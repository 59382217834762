import React, { useContext, useEffect, useState } from "react";
import { Affix, Drawer, Layout } from "antd";
import { useLocation } from "react-router-dom";
import { CompanyContext } from "../../contexts/CompanyContext";
import Footer from "./Footer";
import Header from "./Header";
import Sidenav from "./Sidenav";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const { Header: AntHeader, Content, Sider } = Layout;

function Main({ children }) {
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("right");
  const [sidenavColor, setSidenavColor] = useState("#FFF");
  const [sidenavType, setSidenavType] = useState("transparent");
  const [fixed, setFixed] = useState(false);
  const [collapsed, setCollapsed] = useState(false); // State for collapse
  const { Lang } = useContext(CompanyContext);

  const openDrawer = () => setVisible(!visible);
  const handleSidenavType = (type) => setSidenavType(type);
  const handleSidenavColor = (color) => setSidenavColor(color);
  const handleFixedNavbar = (type) => setFixed(type);
  // Handle the collapse toggle in Sidenav
  const handleSidenavCollapse = (isCollapsed) => {
    setCollapsed(isCollapsed);
  };

  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");

  i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: {
          Entercontext: "Enter the context",
          BudgetConfirmedSuccessfully: "Budget confirmed successfully",
          SalesBudgetDeletedSuccessfully: "Sales budget deleted successfully",
          FailedToDeleteSalesBudget: "Failed to delete sales budget",
          FailedToConfirmBudgetOnServer: "Failed to confirm budget on server",
          deletesalesbudget: "Deletion of the sales budget",
          NoData: "No data to display",
          PleaseSelectAyearToDisplayTheData:
            "Please select a year to display the data",
          SalesBudgetUpdatedSuccessfully: "Sales budget updated successfully",
          FailedToUpdateSalesBudget: "Failed to update sales budget",
          OrderbookDate: "Orderbook Date",
          OrderbookName: "Orderbook Name",
          Difference: "Difference",
          Percentage: "Percentage (%)",
          PerformanceMonitoring: "Performance Monitoring",
          BackToSalesBudgets: "Back to Sales Budgets",
          unlinkedsuccessfully: "Unlinked successfully",
          linkedsuccessfully: "linked successfully",
          unlink: "Dissociate",
          Contractual: "Contractual",
          returntoreportsection: "return to report section",
          SuccessfullyRemovedRolesFromAccount:
            "Successfully removed roles from the account",
          Alldepartments: "All departments",
          FinancialstatementCategory: "Financial Statement Category",
          ImportCharaccounts: "Import Chart accounts",
          Financialratio: "Financial ratio",
          FinancialstatementCategories: "Financial Statement Categories",
          AddNewCategory: "Add a New Category",
          CategoryCreatedSuccessfully: "Category added successfully",
          SomethingWentWrongWhileCreatingCategory:
            "Something went wrong while creating the category",
          Addgroup: "Add group",
          financialStatementClass: "Financial Statement Class",
          pleaseinputthefinancialStatementClass:
            "Please select the financial statement class",
          pleaseinputthelabelinEnglish: "Please enter the label in English",
          pleaseinputthelabelinFrench: "Please enter the label in French",
          labelFr: "Label in French",
          labelEn: "Label in English",
          SomethingWentWrongWhileRecuperingCategories:
            "Something Went Wrong While Recupering the Categories",
          PleaseSelectClassToDisplayTheCategories:
            "Please select a class to display the categories",
          SelectClass: "Select a Class",
          IncludesWhiteLabeling: "Includes white labeling",
          PleaseSelectEmployee: "Please select an employee",
          PleaseSelectPaymentTerm: "Please select a payment term",
          PleaseSelectProduct: "Please select a product",
          NoSocialAdvantages: "No social advantages",
          PleaseAddAPaymentTerm: "Please add a payment term",
          AddPaymentTerm: "Add payment term",
          PaymentTermUpdatedSuccessfully: "Payment term updated successfully",
          PaymentTermAddedSuccessfully: "Payment term added successfully",
          FailedToAddPaymentTerm: "Failed to add payment term",
          PaymentTermDeletedSuccessfully: "Payment term deleted successfully",
          FailedTodeletePaymentTerm: "Failed to delete payment term",
          PasswordsDoNotMatch: "Passwords do not match",
          PasswordSpecialCharError:
            "Password must contain at least 2 special characters",
          PleaseEnterPassword: "Please enter a password",
          PleaseConfirmPassword: "Please confirm the password",
          SalesBudgetSummary: "Sales Budget Summary",
          GoBackToSalesBudget: "Go back to sales budget",
          SalesBudgetDetails: "Sales Budget",
          marketingType: "Marketing type",
          Pleaseselectamarketingtype: "Please select a marketing type",
          selectmarketingtype: "Select a marketing type",
          SalesBudget: "Sales Budget",
          PleaseentermarketingTypelabel: "Please enter a marketing type label",
          LoadingData: "Loading data...",
          ErrorMessage:
            "A Chart Account with the provided GLNumber already exists. Please use a different number.",
          GenericErrorMessage:
            "An unexpected error has occurred. Please try again later.",

          NoSelectedyear:
            "No year selected. Please select a year before duplicating.",
          AreYouSureToDeleteThisChartAccount:
            "Warning! You are about to delete this chart of account. This action is irreversible and will cause all related data to be lost forever. Are you absolutely certain you want to proceed?",
          ChartAccountDeletedSuccessfully:
            "Chart of account deleted successfully",
          FailedToDeleteChartAccount: "Failed to delete chart of account",
          ErrorDeletingChartAccount:
            "Unable to delete the chart of account because it is linked to other data or transactions. Please delete all related data and transactions before deleting the chart of account.",
          Yearly: "Yearly",
          SuccessfullyRemovedRolesFromAccount:
            "Roles successfully removed from the account",
          PleaseSelectAtLeastOneRole: "Please, select at least one role",
          SuccessfullyAddedRolesToAccount:
            "Successfully added roles to the account",
          FailedToAddRolesToAccount: "Failed to add roles to account",
          FailedToAddRoleToAccount: "Failed to add role to account",
          FailedToRemoveRoleFromAccount: "Failed to remove role from account",
          PasswordChangedSuccessfully: "Password changed successfully",
          FailedToChangePassword: "Failed to change password",
          Password: "Password",
          CashFlowModel: "Cash Flow Models",
          CashFlow: "Cashflow",
          NotStarted: "Not Started",
          LowProgress: "Low Progress",
          MeduimProgress: "Medium Progress",
          HighProgress: "High Progress",
          Done: "Done",
          AddNewFinancialstatementType: "Add new financial statement type",
          FinancialstatementType: "Financial statement Type",
          ChargesparDescription: "Expenses by description - ",
          ActivitésMarketingpartype: "Marketing activities by type - ",
          NameofTheOrderBook: "The name of the order book",
          NameofTheOpportunityBook: "The name of the opportunity book",
          opportunitybookcreationerror:
            "Error while the creation the opportunity book",
          Errorwhiledeletingopportunitybook:
            "Error while deleting the opportunity book",
          opportunitybookerror: "Error while creating the opportunity book",
          EnternameOpportunity: "Enter the name of the opportunity book",
          orderbookcreationerror: "Error while creating the order book",
          Entername: "Enter the name of the order book",
          ChartAccountCreatedSuccessfullyForTheyear:
            "Chart of accounts successfully created for the year",
          Reportingsection: "Report generator",
          GoBackToRevenue: "Go back to revenue",
          OverallProgress: "Overall progress",
          HyphothesisUpdatedSuccessfully: "Hyphothesis updated successfully",
          ErrorUpdatingHyphothesis: "Error while updating the hyphothesis",
          GLAccountUpdatedSuccessfully: "GL account updated successfully",
          ErrorUpdatingGLAccount: "GL account updated successfully",
          ExpenseDeletedSuccessfully: "Expense deleted successfully",
          ErrorDeletingExpense: "Error deleting expense",
          HyphothesisCreatedSuccessfully: "Hyphothesis created successfully",
          ErrorCreatingHyphothesis: "Error while creating the hyphothesis",
          HyphothesisDeletedSuccessfully: "Hyphothesis deleted successfully",
          ErrorDeletingHyphothesis: "Error while deleting the hyphothesis",
          deleterowHypo: "Are you sure you want to delete this row ?",
          Activityaddedsuccessfully: "Activity added successfully",
          Anerroroccuredpleasetryagain: "An error occured, please try again",
          ErrorWhileUpdatingStep: "Error while updating step",
          Stepupdatedsuccessfully: "Step updated successfully",
          Stepaddedsuccessfully: "Step added successfully",
          ErrorWhileUpdatingActivity: "Error while updating the activity",
          Stepdeletedsuccessfully: "Step deleted successfully",
          ErrorWhileAddingActivity: "Error while adding the activity",
          Activityupdatedsuccessfully: "Activity updated successfully",
          AnErrorOccurredWhileEditingTheTarget:
            "An error occurred while editing the target",
          ThisTargetContainsUnfinishedSteps:
            "This target contains unfinished steps",
          AnErrorOccurredWhileDeletingTheTarget:
            "An error occurred while deleting the target",
          AnErrorOccurredWhileAddingTheTarget:
            "An error occurred while adding the target",
          TargetAddedSuccessfully: "Target added successfully",
          TargetDeletedSuccessfully: "Target deleted successfully",
          ErrorAddingEmployee: "Error adding employee",
          EmployeeDeletedSuccessfully: "Employee deleted successfully",
          ErrorDeletingEmployee: "Error deleting employee",
          EmployeeAddedSuccessfully: "Employee added successfully",
          ChangesSavedSuccessfully: "Changes saved successfully",
          GoBackToAssets: "Go back to assets",
          GoBackToLiabilities: "Go back to liabilities",
          GoBackToEquity: "Go back to equity",
          GoBackToExpenses: "Go back to expenses",
          ErrorFetchingMarketing: "Error fetching marketing",
          MarketingUpdatedSuccessfully: "Marketing updated successfully",
          MarketingNotFoundForThisYear: "Marketing not found for this year",
          SomethingWentWrongWhileUpdatingMarketing:
            "Something went wrong while updating marketing",
          MarketingCreatedSuccessfully: "Marketing Created Successfully",
          SomethingWentWrongWhileCreatingMarketing:
            "Something went wrong while creating marketing",
          Investmentssavedsuccessfully: "Investments saved successfully",
          Investmentsupdatedsuccessfully: "Investments updated successfully",
          Savehanges: "Save changes",
          pleaseinputtheyear: "Please input the year",
          YearShouldNotBeLessThan0: "Year should not be less than 0",
          RevenueDetail: "Revenue details",
          AreYouSureToDeleteEmployee: "Are you sure to delete the Employee ?",
          ModelCreatedSuccessfully: "Model created successfully",
          AreYousSureDeleteThisCategory: "Are you sure delete this category",
          AreYouSureToDeleteTheEmployee:
            "Are you sure to delete the employee ?",
          ConfirmBudget: "Are you sure you want to confirm the budget ?",
          No: "No",
          Yes: "Yes",
          Operationalprioritization: "Operational prioritization",
          PleaseSelectDepartmentAndYear: "Please select department and year",
          PleaseSelectYear: "Please select year",
          PleaseSelectDepartment: "Please select department",
          Selectgroup: "Please select group",
          group: "Group",
          ErrorWhileSavingChanges: "Error while saving changes",
          PleaseSelectEmployee: "Please select the employee",
          Selectlanguage: "Select language",
          pleaseinputlanguage: "Please select the language",
          pleaseselectcurrency: "Please select the currency",
          pleaseselectplan: "Please select the plan",
          Selectcurrency: "Select currency",
          Dashboard: "Dashboard",
          Choosefrequency: "Choose a frequency",
          Selectintegrator: "Select an integrator",
          Selectplan: "Select a plan",
          MarginUpdatedSuccessfully: "Margin updated successfully",
          ErrorWhileUpdatingMargin: "Error while updating margin",
          ErrorWhileDeletingTheMarge: "Error while deleting the marge",
          MargeDeletedSuccessfully: "Marge deleted successfully",
          ErrorWhileAddingTheMarge: "Error while adding the marge",
          MargeAddedSuccessfully: "Marge added successfully",
          Standard: "Standard",
          Personalized: "Personalized",
          Personalizedrapport: "Custom report",
          Reportdeletedsuccessfully: "Report deleted successfully",
          Failedtogeneratereportbecauseithasnocustomdata:
            "Failed to generate report because it has no custom data",
          Reportgeneratedsuccessfully: "Report generated successfully",

          Period: "Period",
          from: "From",
          to: "To",
          months: "Months",
          displaydetails: "Display details",
          summaries: "Summaries",
          Documentsizenorientation: "Document size & orientation",
          orientation: "Orientation",
          size: "Size",
          checkall: "Check all",
          generatereport: "Generate report",
          savereportoptions: "Save report options",
          other: "Other",
          currency: "Currency",
          languages: "Languages",
          name: "Name",
          entername: "Enter a name",
          Landscape: "Landscape",
          Portrait: "Portrait",
          letter: "Letter",
          Totals: "Totals",
          Averages: "Averages",
          Glnumber: "GL number",
          Client: "Client",
          pleaseselectamonth: "Please select a month",
          selectmonth: "Select months",
          namerepport: "Name the report",
          TotalRevenue: "Total of Revenues",
          selectoption: "Select an orientation",
          Type: "Type",
          AnnualBudget: "Annual budget",
          selectsize: "Select size",
          Status: "Status",
          OpportunityExpectedRevenueTotal: "Opportunity expected revenue total",
          FromAnOpportunity: "From an Opportunity",
          OrderRevenueTotal: "Order revenue total",
          ExpectedRevenueTotal: "Expected revenue total",
          Expectedtotal: "Expected total",
          LoanAddedSuccessfully: "Loan added successfully",
          ErrorAddingLoan: "Error adding loan",
          ErrorDeletingLoan: "Error deleting loan",
          ErrorFetchingLoans: "Error fetching loans",
          LoanDeletedSuccessfully: "Loan deleted successfully",
          LoanUpdatedSuccessfully: "Loan updated successfully",
          AnerrorhasoccuredWhileUpdatingtheLoan:
            "An error has occured while updating the Loan",
          ParametersforMarketingType: "Parameters for marketing type",
          Updatedsuccessfully: "Updated successfully",
          for: "for",
          Pleaseenterlabel: "Please enter label",
          addTitle: "Add title",
          Veuillersaisirlabel: "Please input the label",
          ErrorFetchingTitles: "Error fetching titles",
          TitlesUpdatedSuccessfully: "Titles updated successfully",
          Titledeletedsuccessfully: "Title deleted successfully",
          ErrorDeletingTitle: "Error deleting title",
          Personalized: "Personalized",
          PleaseinputthePhoneNumber: "Please input the phone number",
          Pleaseinputthetype: "Please input the type",
          InvestmentActivitiesByCategory: "Investment activities by category",
          TotalInvestmentActivitiesPerMonth:
            "Total investment activities per month",
          TotalInvestmentPerYear: "Total investment activities per year",
          SlectATitle: "Select a aitle",
          AverageAgeErrorMessage: "Select the average age",
          OutflowPart: "Outflow part",
          CommonPart: "Common part",
          Complexity: "Complexity",
          Frequency: "Frequency",
          PleaseInputYourLastName: "Please input your last name",
          PleaseChooseDateJoin: "Please choose the date of joining",
          AnErrorOccurredWhileCreatingTheIntegratorAccount:
            "An error occurred while creating the integrator account",
          UpdatePlan: "Update plan",
          SelectAll: "Select all",
          PleaseinputtheEmail: "Please input the email",
          CreateAnewUser: "Create a new user",
          EmployeeSelection: "Employee selection",
          PleaseSelectATitle: "Please select a title",
          PleaseSelectAnEmployee: "Please select an employee",
          PleaseEnterAnEmail: "Please enter an email",
          PleaseEnterAPhoneNumber: "Please enter a phone number",
          EmployeeAccountCreatedSuccessfully:
            "Employee account created successfully",
          ErrorFetchingEmployeesForSelectedTitle:
            "Error fetching employees for selected title",
          NoEmployeesAssociatedWithSelectedTitle:
            "No employees associated with the selected title",
          PM: "PERFORMANCE MEASURES",
          AME: "ASSET MANAGEMENT EFFICIENCY",
          OP: "OPERATING PROFITABILITY",
          IntegratorCreatedSuccessfully: "Integrator created successfully",
          FailedtoCreateIntegrator: "Failed to create integrator",
          UnselecteAll: "Unselecte all",
          ErrorFetchingIntegratorAccounts: "Error fetching integrator accounts",
          ErrorFetchingEmployeeAccounts: "Error fetching employee accounts",
          ErrorCreatingEmployeeAccount: "Error creating employee account",
          ErrorCreatingRole: "Error creating role",
          IntegratorAccountUpdated: "Integrator account updated successfully",
          Currencies: "Currencies",
          ShortLabel: "Short label",
          SaveCurrency: "Save currency",
          CancelEditing: "Cancel editing",
          EditCurrency: "Edit currency",
          AddNewCurrency: "Add new currency",
          CurrencyAddedSuccessfully: "Currency added successfully",
          CurrencyUpdatedSuccessfully: "Currency updated successfully",
          ErrorSavingCurrencies: "Error saving currencies",
          Languages: "Languages",
          AddNewLanguage: "Add new language",
          ErrorFetchingLanguages: "Error fetching languages",
          LanguageAddedSuccessfully: "Language added successfully",
          LanguageUpdatedSuccessfully: "Language updated successfully",
          ErrorSavingLanguages: "Error saving languages",
          SaveLanguage: "Save language",
          EditLanguage: "Edit language",
          Security: "Security",
          Accounts: "Accounts",
          GoBack: "Go back",
          Integrators: "Integrators",
          FirstName: "First name",
          EnterFirstName: "Enter first name",
          LastName: "Last name",
          EnterLastName: "Enter last name",
          DateJoined: "Join date",
          Email: "Email",
          SelectDate: "Select date",
          EnterEmail: "Enter email",
          EnterPhoneNumber: "Enter phone number",
          EnterPassword: "Enter password",
          GeneratePassword: "Generate password",
          ConfirmPassword: "Confirm password",
          YourPasswordHasBeenGenerated:
            "The password has been generated. Please save this password in a secure place. You will not be able to recover it if you lose it.",
          DepartureDate: "Departure date",
          PhoneNumber: "Phone number",
          LastLogin: "Last login",
          CreateAnIntegrator: "Create an integrator",
          IntegratorInfos: "Integrator infos",
          AccountInfos: "Account's information",
          AnErrorOccuredWhileUpdatingPlan:
            "An error occured while updating the plan",
          PlanUpdated: "Plan updated successfully",
          RoleVisibility: "Role visibility",
          seenByIntegrator: "Seen by integrator",
          seenByClient: "Seen by client",
          // Investment :
          EditPlan: "Edit plan",
          RoleDeactivated: "Role deactivated successfully",
          AnErrorOccuredWhileDeactivatingRole:
            "An error occured while deactivating role",
          RoleActivatedSuccessfully: "Role activated successfully",
          AnErrorOccuredWhileactivatingRole:
            "An error occured while activating role",
          OurPlans: "Our plans",
          CurrentPrice: "Current price",
          ChooseFile: "Choose File",
          IncludedSections: "Included sections",
          PlanDoesNotIncludeAnySections: "Plan does not include any sections",
          planCreatedSuccessfully: "Plan created successfully",
          PlanSuccessfullyDeleted: "Plan successfully deleted",
          FailedToDeletePlan: "Failed to delete plan",
          ErrorDeletingPlan: "Error deleting plan",
          IncludingWhiteLabeling: "Including white labeling",
          PlanModules: "Plan modules",
          Employees: "Employees",
          IncludesWhiteLableing: "Includes white lableing",
          PleaseInputTheCostPerExtraUser:
            "Please input the cost per extra users",
          EnterCostPerExtraUser: "Enter cost per extra users",
          CostPerExtraUser: "cost per extra users",
          EnterStandardPrice: "Enter standard price",
          PleaseInputTheStandardUsers: "Please input the standard users limit",
          PleaseInputTheStandardPrice: "Please input the standard price",
          EnterDescription: "Enter description",
          CreateANewPlan: "Create a new plan",
          PleaseInputTheLabel: "Please input the label",
          Frequency: "Frequency",
          EnterStandardUsers: "Enter standard users limit",
          StandardUsers: "Standard users limit",
          SelectFrequency: "Choose a frequency",
          PleaseSelectTheFrequency: "Please select the frequency",
          EnterLabel: "Enter label",
          Label: "Label",
          standardPrice: "Standard price",
          IntegratorName: "Integrator name",
          Desactivate: "Desactivate",
          Activated: "Activated",
          AccountStatus: "Account status",
          PlanGeneralInfos: "Plan general informations",
          IntegratorAccountDeactivated:
            "Integrator account deactivated successfully",
          IntegratorAccountactivated:
            "Integrator account activated successfully",
          AnErrorOccuredWhileactivatingIntegratorAccount:
            "An error occured while activating integrator account",
          AnErrorOccuredWhileDeactivatingIntegratorAccount:
            "An error occured while deactivating integrator account",
          NotYetConnected: "Not yet connected",
          NoRoleAssociated: "No role associated",
          SureToCancel: "Sure to cancel",
          NewUser: "New user",
          UpdateEmployeeAccount: "Update employee account",
          EmployeeAccountUpdated: "Employee account updated successfully",
          AnErrorOccuredWhileUpdatingEmployeeAccount:
            "An error occured while updating employee account",
          NotYetDeparted: "Not yet departed",
          Users: "Users",
          ProjectNumber: "Project number",
          Nameofrequester: "Name of requester",
          Pleaseinputthenameofrequester:
            "Please enter the name of the applicant",

          Objectifprincipalduprojet: "Main objective of the project",

          ProjectName: "Project name",
          ProjectFile: "Project file",
          ProjectFiledetail: "Project file detail",
          InvestmentProjectFilesAddedSuccessfully:
            "Investment project file added successfully",
          AddfileProject: "Add file project",
          CanBeSeenByIntegrator: "Seen by integrator",
          CanBeSeenByClient: "Seen By Client",
          // Investment :
          lang: "FR",
          general: "General",
          Category: "Category",
          Budgets: "Budgets",
          Reals: "Reals",
          Performances: "Performances",
          BudgetConfirmed: "Budget confirmed",
          BudgetTotal: "Budget total",
          RealTotal: "Real total",
          PerformanceTotal: "Performance total",
          SelectedYear: "Selected year",
          monthbusinessdays: "Month business days",
          Categories: "Categories",
          DeleteInvestment: "Delete investment",
          DeleteBudegt: "Delete budegt",
          DeleteReal: "Delete real",
          Confirm: "Confirm",
          Unconfirm: "Unconfirm",
          Confirmed: "Confirmed",
          Investment: "Investment",
          budgetConfirmed: "Budget confirmed",
          InvestmentSummary: "Summary of investments",
          SaveChanges: "Save",
          BackToInvestments: "Back to investments",
          CreateInvestment: "Create investment",
          CategoriesOfInvestments: "Categories of investments",
          Pleaseselectyearbeforeaddinginvestment:
            "Please select a year before adding an investment",
          AddNewCategory: "Add new category",
          EditCategory: "Edit category",
          SaveCategory: "Save category",
          DeleteCategory: "Delete category",
          ExportToExcel: "Export to excel",
          CategoryAddedSuccessfully: "Category added successfully",
          CategoryUpdatedSuccessfully: "Category updated successfully",
          ErrorFetchingCategories: "Error fetching categories ",
          ErrorSavingCategories: "Error saving categories",
          categoryDeletedSuccessfully: "Category deleted successfully!",
          ErrorDeletingCategory: "Error deleting category!",
          Parameters: "Parameters",
          Pleaseselectcategory: "Select a category",
          PleaseInputYourFirstName: "Please input your first name",
          Passwordsdonotmatch: "Passwords do not match",
          AccessControl: "Access control",
          ManageSections: "Manage sections",
          ManageAccess: "Manage access",
          ManageTeam: "Manage team",
          ManageRoles: "Manage roles",
          ManageTitles: "Manage titles",
          ManageAccounts: "Manage accounts",
          AddNewAccount: "Add new account",
          CreateAccount: "Create account",
          AddNewRole: "Add new role",
          SelectRole: "Select role",
          ChooseRoleToManageAccess: "Choose role to manage access",
          RoleAlreadyExists: "Role already exists",
          RoleCreatedSuccessfully: "Role created successfully!",
          ErrorUpdatingRole: "Error updating role",
          AddNewMnager: "Add new manager",
          CreateRole: "Create role",
          PleaseEnterRoleName: "Please enter role name",
          RoleNames: "Role names",
          PleaseEnterTheEmail: "Please enter the email",
          ChangePassword: "Change password",
          UpdateAccount: "Update account",
          AccountDetails: "Account details",
          CurrentPassword: "Current password",
          NewPassword: "New password",
          AddRoleToAccount: "Add role to account",
          RemoveRoleFromAccount: "Remove role from account",
          NA: "N/A",
          PleaseEnterThePhoneNumber: "Please enter the phone number",
          PleaseSelectAManager: "Please select a manager",
          PleaseEnterThePassword: "Please enter the password",
          PleaseSelectAtLeastOneRole: "Please select at least one role",
          RoleName: "Role name",
          EditRole: "Edit role",
          SaveRole: "Save role",
          CancelEdit: "Cancel edit",
          RoleUpdatedSuccessfully: "Role updated successfully",
          AddNewSection: "Add new section",
          AddSection: "Add section",
          AddSubsection: "Add subsection",
          AddSubsectionTo: "Add subsection to",
          CancelSubsection: "Cancel subsection",
          SubsectionName: "Subsection name",
          ConfirmDeleteSection: "Confirm delete section",
          NoSubSections: "No sub-sections available for this section",
          NotYetAuthenticated: "Not yet authenticated",
          NoCancel: "No, cancel",
          YesDelete: "Yes, delete it",
          DeleteThisSection:
            "Are you sure you want to delete this section and all associated subsections ?",
          EditSection: "Edit section",
          DeleteSection: "Delete section",
          SectionName: "Section name",
          ParentSection: "Parent section",
          SubsectionCreatedSuccess: "Subsection created successfully!",
          FailedCreateSubsection: "Failed to create subsection!",
          SubsectionUpdatedSuccess: "Subsection updated successfully!",
          PleaseEnterTheSectionName: "Please enter the section name",
          SectionAlreadyExists: "Section already exists!",
          SectionCreatedSuccessfully: "Section created successfully!",
          SectionUpdatedSuccessfully: "Section updated successfully!",
          SectionDeletedSuccessfully: "Section deleted successfully!",
          FailedCreateSection: "Failed to create section",
          SectionAndAssociatedSubsectionsDeletedSuccessfully:
            "Section and associated subsections are deleted successfully",
          FailedDeleteSectionAndAssociatedSubsections:
            "Failed to delete section and associated subsections!",
          SaveAccess: "Save access",
          AccessSavedSuccessfully: "Access saved successfully",
          ErrorSavingAccess: "Error saving access",
          SelectParentSection: "Select parent section",
          FilterWithParentSection: "Filter with parent section",
          UpdateCompany: "Update company",
          textupdate:
            "Please fill out the required fields below and click on the Save button to update your company's information",
          generalinf: "General information",
          companyname: "Company name",
          address: "Address",
          country: "Country",
          province: "Province",
          city: "City",
          postalcode: "Postal code",
          foundingdate: "Founding date",
          Yearenddate: "Year-end date",
          loadingcompanyinformation: "Loading company information...",
          Loadingfinished: "Loading finished",
          dashboard: "Dashboard",
          sales: "Sales",
          Orderdeletedsuccessfully: "Order deleted successfully",
          Opportunitydeletedsuccessfully: "Opportunity deleted successfully",
          OrdercostdetailsupdatedSuccessfully:
            "Order cost details updated successfully",
          OpportunityrevenuedetailsupdatedSuccessfully:
            "Opportunity revenue details updated successfully",
          OrderupdatedSuccessfully: "Order updated successfully",
          OpportunityupdatedSuccessfully: "Opportunity updated successfully",
          logout: "Sign out",
          company: "Add company",
          select: "Select and access company information",
          Targetcustomers: "Target Customers",
          Market: "Market",
          Themaincustomers: "Customers",
          Revenuemodel: "Revenue models",
          Businesspartners: "Business partners",
          Businessnumber: "Business number",
          Typeofindustry: "Type of industry",
          Postalcode: "Postal code",
          Foundingdate: "Founding date",
          Estimatedannualtaxrate: "Estimated annual tax rate (%)",
          Numberofemployees: "Number of employees",
          editcompany: "Edit information",
          deletecompany: "Delete company",
          deletecompanytext: "Are you sure you want to delete",
          addedsuccessfully: "Values added successfully",
          Suretodelete: "Are you sure you want to delete ?",
          PleaseInputTheBusinessNumber: "Please input the Business number",
          Pleaseinputthe: "Please input the name of",
          addnewholder: "Add new holder",
          pleaseinputthefoundingdate: "Please input the founding date",
          pleaseinputthecity: "Please input the city",
          valuesaddedSuccessfully: "Values added successfully",
          orderbook: "Order book",
          orderbookdeleted: "Order book deleted successfully",
          assignmentdeleted: "Assignment deleted successfully",
          yearnotexisting: "Data for this year doesn't exist",
          orderbookcreation: "Order book added successfully",
          orderbookupdate: "Order book updated successfully",
          deleterow: "Are you sure you want to delete this row ?",
          orderbooknamerequired: "Please input a name for the order book!",
          assignmentnamerequired: "Please input a name for the assignment!",
          billedhours: "Number of billed hours total",
          availablehours: "Available hours total",
          holidayhours: "Total holiday hours",
          vacationhours: "Total vacation hours",
          illnesshours: "Total illness hours",
          unpaidhours: "Total unpaid hours",
          paidhours: "Total paid hours",
          billingavg: "Average billing",
          grossmarginavg: "Average gross margin",
          estimatedclientinvoice: "Total estimated client invoices",
          totalclientinvoice: "Total client invoices",
          variance: "Variance",
          enterworkhours: "Enter work hours",
          workhoursrequired: "Work hours required",
          workhours: "Workhours",
          numberofday: "Number of days",
          numberofdayrequired: "Number of days required",

          costrate: "Cost rate",
          sellingrate: "Selling rate",

          ErrorWhileDeletingassignment: "Error While Deleting Assignment",
          assignmentcreation: "Assignment Created Successfully",
          assignmentupdate: "Assignment Updated Successfully",
          assignmentcreationerror: "Assignment Creation Error",
          NameofTheassignment: "Name of the Assignment",
          creationdate: "Creation date",
          selectcustomer: "Select a customer",
          selectproduct: "Select a product",
          YearsInterval: "Years interval",
          valuecannotbelessthan0: "Value cannot be less than 0",
          PleaseInputTheYearsInterval: "Please input the years interval",
          addnewmanager: "Add new manager",
          Pleaseenteradate: "Please enter a date",
          opportunitybook: "Opportunity book",
          opportunitybookdeleted: "Opportunity book deleted successfully",
          opportunitybookcreation: "Opportunity book added successfully",
          opportunitybookupdate: "Opportunity book updated successfully",
          opportunitybooknamerequired:
            "Please input a name for the opportunity book",
          ReturntoGeneralInformations: "Return to General Information",
          Addanothercompany: "Add another company",
          AddCompany: "Add company",
          textButtonAJT:
            "Please fill out the required fields below and click on the Submit button to add your company's information",
          generalInformation: "General information",
          Pleaseinputthecompanyname: "Please input the company name",
          ProvinceSelect: "Select the province",
          selectthetypeofindustry: "Select the type of industry",
          selectthebudget: "Select the budget",
          selectdate: "Select date",
          pleaseenteranumberbetween0and100:
            "Please enter a number between 0 and 100",
          pleaseselectthestrategictarget: "Please select the strategic target",
          Pleaseinputthetypeofstrategictarget:
            "Please input the type of strategic target",
          PleaseinputtheShareHolders: "Please input the shareholders",
          SelectStartPeriod: "Select start period",
          Pleaseinputthemanagertitle: "Please input the manager title",
          selecttheproductsservices: "Select the products/services",
          Pleaseinputthemanagerfirstname:
            "Please input the manager's first name",
          Pleaseinputthemanagerlastname: "Please input the manager's last name",
          type: "Type",
          YourCompanyhasbeenaddedsuccessfully:
            "Your company has been added successfully",
          TheCompanyhasbeenupdatedsuccessfully:
            "The company has been updated successfully",
          Selectacompanytodisplayitsdata:
            "Select a company to display its data",
          Pleaseentercustomername: "Please enter customer name",
          Companydeleted: "Company deleted successfully!",
          selectthemarket: "Select the market",
          addtitles: "Add titles",
          MainCustomers: "Customers",
          selectthemaincustomers: "Select the customers",
          RevenueModel: "Revenue model",
          AddnewmarketButton: "Add new market",
          AddnewcustomerButton: "Add new customers",
          RevenueModelButton: "Add new revenue model",
          selecttherevenuemodel: "Select the revenue model",
          BusinesspartnersButton: "Business partners",
          typeofactivities: "Type of activities",
          selectthebusinesspartners: "Select the business partners",
          Addnewbusinesspartner: "Add new business partner",
          Descriptionofservicesandproducts:
            "Description of services and products",
          Descriptionofoffer: "Description of the offer",
          strategicplanning: "Strategic planning",
          selectthestrategictarget: "Select the strategic target",
          selectthetypeofactivities: "Select the type of activities",
          Descriptionplaceholder: "Please write a description",
          Descriptionofprojet: "Project description",
          Descriptionplace: "Write a description",
          BookTotal: "Book total",
          CreateanewStratigicTarget: "Create a new strategic target",
          writeacostumer: "Please write a customer",
          writeaproduct: "Please write a product",
          Strategictargets: "Strategic targets",
          CreateanewStrategictargets: "Create a new strategic target",
          Typeofactivities: "Type of activities",
          ProductsServices: "Products/services",
          StrategictargetsButton: "Add new strategic targets",
          TypeofactivitiesButton: "Add new type of activities",
          ProductsServicesButton: "Add new products/services",
          Startyear: "Start year",
          StartPeriod: "Start period",
          Selectdate: "Select date",
          Selectmonth: "Select month",
          Selectyear: "Select year",
          Managementteam: "Management team",
          Createanewmanager: "Create a new manager",
          Leadersname: "Leader's name",
          addtolist: "Add to list",
          pleaseselectthebusinesspartner: "Please select the business partner",
          Lastname: "Leader's name",
          Firstname: "First name",
          Titles: "Titles",
          Title: "Title",
          Yearsofexperience: "Years of experience",
          cancel: "Cancel",
          create: "Create",
          Delete: "Delete",
          edit: "Edit",
          save: "Save",
          details: "Details",
          pleaseselectthebudget: "Please select the budget",
          CitySelect: "Please select a city",
          pleaseselecttheproductservice: "Please select the product/service",
          Managetitles: "Manage titles",
          Createanewtitle: "Create a new title",
          Addtitle: "Add title",
          EditTitles: "Edit titles",
          Titlelabel: "Title label",
          selectleader: "Select a leader",
          selectShareHolders: "Select a shareholder",
          Selectsharepourcent: "Select percentage",
          Addmanager: "Add manager",
          Legalstructure: "Legal structure",
          ShareHolders: "Shareholders",
          CreateanewShareholder: "Add the shareholder",
          Nameshareholders: "Name of shareholders",
          Shares: "Shares",
          percentshares: "% of shares",
          Startdate: "Start date",
          Addshareholders: "Shareholders",
          submit: "Submit company",
          yes: "Yes",
          no: "No",
          opportunities: "Opportunities",
          IndustryTypes: "Industry types",
          nodata: "No data found",
          pleaseselectthemarket: "Please select the market",
          AddnewtypeIndustry: "Add new type of industry",
          market: "Market",
          Label: "Label",
          Name: "Name",
          PleaseInputTheStartYear: "Please input the start year",
          PleaseInputTheStartPeriod: "Please input the start period",
          pleaseselecttherevenuemodel: "Please select the revenue model",
          Pleaseinputthedetailseofstrategictarget:
            "Please input the details of strategic target",
          Pleaseselectacustomer: "Please select a customer",
          pleaseinputtheaddress: "Please input the address",
          pleaseinputthepostalcode: "Please input the postal code",
          pleaseinputthestartdate: "Please input the start date",
          Pleaseenterproductlabel: "Please enter product label",
          Selectopportunitystatus: "Select opportunity status",
          Open: "Open",
          Waiting: "Waiting",
          Suspended: "Suspended",
          Lost: "Lost",
          Customer: "Customer",
          Product: "Product",
          Startdatee: "Start date",
          Enddate: "End date",
          Priceperday: "Price per day",
          Description: "Description",
          Createanewopportunity: "Create a new opportunity",
          SearchtoSelect: "Search to select",
          selectmanagerstitles: "Select manager's titles",
          pleaseselectthecustomer: "Please select the customer",
          writeaproductplaceholder: "Write a product",
          Commands: "Orders",
          RevenueDetails: "Revenue Details for ",
          January: "January",
          pleaseselectthetypeofindustry: "Please select the type of industry",
          pleaseselectthecountry: "Please select the company's country",
          February: "February",
          March: "March",
          April: "April",
          May: "May",
          June: "June",
          July: "July",
          August: "August",
          September: "September",
          October: "October",
          November: "November",
          December: "December",
          Action: "Action",
          CreateCustomer: "Create customer",
          Configurationentity: "Configuration entity",
          Revenutype: "Revenue type",
          CreateProduct: "Create product",
          Createbook: "Create order",
          Createopportunity: "Create opportunity",
          RevenueSummary: "Revenue summary",
          Createaneworder: "Create a new order",
          Customersname: "Customer's name",
          Productslabel: "Product's label",
          CreateanewProduct: "Create a new product",
          nameproduct: "Product name",
          pleaseselecttheactivitytype: "Please select the activity type",
          CreateanewCustomer: "Create a new customer",
          Pleaseselectastatus: "Please select a status",
          titlePage: "Strategic planning follow-up",
          title: "Create a new strategic target",
          statut: "Status",
          Progression: "Progress",
          Departement: "Department",
          selectdatefin: "Select end date",
          selectdatedebut: "Select start date",
          Pleaseselectaproduct: "Please select a product",
          Finance: "Finance",
          Loansmanagement: "Loans management",
          Create: "Create",
          Loanname: "Loan name",
          Projectnumber: "Project number",

          Creationdate: "Creation date",
          Operation: "Operation",
          Details: "Details",
          Loan: "Loan",
          Addaloan: "Add a loan",
          Creditor: "Creditor",
          Loanamount: "Loan amount",
          Terms: "Terms",
          Loandate: "Loan date",
          Capitalfreeperiod: "Capital-free period",
          Interestrates: "Interest rates",
          Particularconditions: "Particular conditions",
          writeacostumerplaceholder: "Write a customer",
          Veuillersaisirlenomdelinstitut: "Please input the institut name",
          Veuillezchoisirlemontantdupret: "Please input the loan amount",
          chooseaterm: "Please choose a term",
          sixtymonths: "sixty months",
          thirtymonths: "thirty months",
          ninetymonths: "ninety months",
          EditePret: "Edit a loan",
          Edit: "Edit",
          Cancel: "Cancel",
          Update: "Update",
          Margin: "Margin",
          Addamargin: "Add a margin",
          Marginsmanagement: "Margins management",
          Institutionname: "Institution name",
          Authorizedamount: "Authorized amount",
          pleaseentertheAuthorizedamount: "Please enter the amount",
          EditerlaMarge: "Edit a margin",
          home: "Home",
          add12months: "ENG",
          duplicatelastorderbook: "Duplicate last order book",
          duplicatelastopportunitybook: "Duplicate the last opportunity book",
          duplicatelastassignment: "Duplicate last assignment",
          Gained: "Gained",
          newtarget: "New target",
          strategictargets: "Strategic targets",
          detail: "Detail",
          status: "Status",
          progression: "Progression",
          departements: "Departments",
          targeteditedsuccessfully: "Target edited successfully",
          pleaseinputthetype: "Please input the type!",
          CreateanewStrategicTarget: "Create a new Strategic Target",
          associateastrategictarget: "Associate a strategic target",
          selectatarget: "Select a target",
          updatestep: "Update target",
          associate: "Associate",
          dissociate: "Dissociate",
          dissociatemsg: "Are you sure you to remove",
          newstep: "New step",
          priority: "Priority",
          projectnumber: "Project number",
          responsable: "Responsible",
          predicteddate: "Predicted date",
          enddate: "End date",
          comments: "Comments",
          createanewstep: "Create a new step",
          selectaresponsibleplease: "Select a responsible",
          enterprojectnumberplease: "Enter a project number",
          selectanestimatedfinishdate: "Select an estimated finish date",
          Selectadate: "Select a date",
          selectapriority: "Select a priority",
          AddfileProjecr: "Add a file project",
          enteracommentplease: "Enter a comment",
          activity: "Activity",
          activities: "Activities",
          newactivity: "New activity",
          createanewactivity: "Create a new activity",
          enteralabl: "Enter a label",
          selectastatus: "Select a status",
          activityeditedsuccessfully: "Activity edited successfully",
          activitdeletedsuccessfully: "Activity deleted successfully",
          activityname: "Activity name",
          Goback: "Go back",
          high: "High",
          medium: "Medium",
          low: "Low",
          updateactivity: "Update activity",
          cannotdissociateatargetifithasactivitiesinitssteps:
            "Cannot dissociate a target if it has activities in its steps!",
          targetassociated: "New target associated with the department!",
          targetdissociated: "Target dissociated from the department!",
          cannotdeleteastepifithasoneormoreactivities:
            "Cannot delete a step if it has one or more activities!",
          Chartaccounts: "Chart of accounts",
          Print: "Print",
          InvestmentCategories: "Categories",
          Createanewaccount: "Create a new account",
          Upload: "Upload",
          Gifi: "GIFI",
          Class: "Class",
          GLnumber: "GL number",
          Chartofaccount: "Chart of account",
          Account: "Account",
          Additionalinformation: "Additional information",
          Annualbudget: "Annual budget",
          Budgetbreakdown: "Budget breakdown",
          Note: "Note",
          Pleaseinputtheaccount: "Please input the account",
          Pleaseinputtheclass: "Please input the class",
          Pleaseinputthecategory: "Please input the category",
          Pleaseinputthegifi: "Please input the GIFI",
          Pleaseinputtheannualbudget: "Please input the annual budget",
          Areyousuretodeletethisone: "Are you sure to delete this one",
          GLaccount: "Gl account",
          Hypotheses: "Hypothesis",
          Createanewhypothesis: "Create a new Hypothesis",
          Year: "Year",
          Annualincrease: "Annual increase",
          Beginon: "Begin on",
          GLAccountsTheirHyphothesis: "GL accounts & their hypothesis",
          Filterbytype: "Filter by type",
          Selectafinancialstatementtype: "Select a financial statement type",
          Relatedgifi: "Related GIFI",
          Pleaseinputtheyear: "Please input the year",
          Pleaseinputthehypothesis: "Please input the hypothesis",
          Pleaseinputtheannualincrease: "Please input the annual increase",
          Pleaseselectamonth: "Please select a month",
          UpdatedSuccessfully: "Updated successfully",
          Alltypes: "All GIFI",
          Financialstatements: "Financial statements",
          glnumber: "GL number",
          liabilities: "Liabilities",
          Liabilitysummary: "Liabilities summary",
          summary: "Summary",
          Uploadthereal: "Upload the real",
          Maininformation: "Main information",
          PleaseSelectclass: "Please select a class",
          PleaseSelectcategory: "Please select a category",
          Hyphothesis: "Hypothesis",
          CreateanewHyphothesis: "Create a new hypothesis",
          PleaseinputtheHyphothesis: "Please input the hypothesis",
          backtofinancialstatement: "Back to financial statement",
          deletStatement: "Delete statement",
          Budget: "Budget",
          Average: "Average",
          Difference: "Difference",
          Performance: "Performance",
          LiabilitiesDetail: "Liabilities detail",
          Deletethestatment: "Delete the statement",
          AnnualIncrease: "Annual increase",
          BeginOn: "Begin on",
          BeginOnMonth: "Begin on month",
          CreateHypothesis: "Create hypothesis",
          CreateHypothesisss: "Create hypothesis",
          equity: "Retained earnings",
          EquityDetails: "Equity details",
          Equitysummary: "Equity summary",
          Asset: "Assets",
          AssetsDetails: "Asset details",
          AssetSummary: "Asset summary",
          Taxable: "Taxable",
          PayementTerme: "Payment term",
          ExpenseDetails: "Expense details",
          Expense: "Expense",
          Expenses: "Expenses",
          ExpenseSummary: "Expense summary",
          Revenue: "Revenue",
          Revenues: "Revenue",
          sellingPrice: "Selling price",
          Employee: "Employee",
          SelectEmployee: "Select employee",
          ExpectedSoldUnits: "Expected sold units",
          Areyousuretoconfirm: "Are you sure to confirm",
          RealSoldUnit: "Real sold units",
          HumanResource: "Employee informations",
          HumanResources: "Human resources",
          AddEmployee: "Add employee",
          EmployeesManagement: "Employees management",
          CreateNewEmployeeFor: "Create new employee for",
          EmployeeFirstName: "First name",
          EmployeeFirstNameErrorMessage: "Please provide a first name",
          EmployeeLastName: "Last name",
          EmployeeLastNameErrorMessage: "Please provide a last name",
          EmployeeGender: "Gender",
          EmployeeGenderErrorMessage: "Please select a gender",
          EmployeeStatuss: "Employee status",
          EmployeeStatusErrorMessage: "Select a status",
          AgeAverage: "Age",
          AgeAverageErrorMessage: "Select age",
          VisibilityMinority: "Visibility minority",
          RecognizedExeperienceYears: "Recognized experience years",
          RecognizedExeperienceYearsErrorMassage: "Enter a number of years",
          YearsOfExperienceTotal: "Years of experience total",
          YearsOfExperienceTotalErrorMessage: "Enter a number of years",
          AnnualSalary: "Annual salary",
          AnnualSalaryErrorMessage: "Please provide an amount",
          SocialAdvantages: "Social advantages",
          SocialAdvantagesErrorMessage: "Please provide the amount",
          AverageAge: "Average age",
          BasicConditions: "Basic conditions",
          BreakDown: "Breakdown",
          Vaccation: "Vacation",
          Bonus: "Bonus",
          OtherAdvantages: "Other advantages",
          JobPostingStartDate: "Job posting start date",
          JobPostingStartDateErrorMessage: "Select a date",
          InsuranceContribution: "Insurance contribution",
          Job: "Job",
          ImmediateSuperior: "Immediate superior",
          TitleErrorMessage: "Select a title",
          JobClassification: "Job classification",
          Linkingdepartmeent: "Linking department",
          JobClassificationErrorMessage: "Select a classification",
          HiringDate: "Hiring date",
          HiringDateErrorMessage: "Select a date",
          Department: "Department",
          Linkinggroup: "Financial ratios parameters",

          LinkinggroupwithChartofAccounts:
            "Linking group with Chart of Accounts",
          DepartmentErrorMessage: "Department is required",
          PayPeriod: "Pay period",
          JobStatus: "Job status",
          PostStatus: "Post status",
          DepartureDate: "Departure date",
          DepartureDateErrorMessage: "Select a date",
          HumanResourcesPlanning: "Salary expenses",
          JobTitle: "Job title",
          GeneralIdentification: "General identification",
          Active: "Active",
          Male: "Male",
          Female: "Female",
          UnpaidLeave: "Unpaid leave",
          Illness: "Illness",
          InvoluntaryDeparture: "Involuntary departure",
          VoluntaruDeparture: "Voluntary departure",
          Invalidity: "Invalidity",
          Under20: "Under 20",
          Between20And25: "Between 20 and 25",
          Between26And30: "Between 26 and 30",
          Between31And39: "Between 31 and 39",
          Between40And50: "Between 40 and 50",
          Beyound50: "Beyond 50",
          SlectAJobClassification: "Select a job classification",
          Weekly: "Weekly",
          In15Days: "In 15 days",
          Monthly: "Monthly",
          FullTime: "Full-time",
          PartTime: "Part-time",
          Temporary: "Temporary",
          Completed: "Completed",
          Eliminated: "Eliminated",
          SelectDepartment: "Select department",
          Alldepts: "All departments",
          CreateNewDepartment: "Create new department",
          currentform: "Current form",
          previousupdate: "Previous update",
          DeleteDepartment: "Delete department",
          UpdateDepartment: "Update department",
          AddDepartment: "Add department",
          postclassficiation: "Post classifications",
          UpdatePostClassification: "Update post classification",
          CreateNewPostClassification: "Create new post classification",
          PostClassificationaddedsuccessfully:
            "Post classification added successfully",
          AddPostClassification: "Add post classification",
          DeletePostClassification: "Delete post classification",
          EnterpriseTitles: "Enterprise titles",
          CreateNewTitle: "Create new title",
          employeedetails: "Employee details",
          HumanResourcesSummary: "Human Resources Summary",
          HumanResourcesPlanningHeader: "HR planning",
          SelectDepartmentPlaceHolder: "Select department",
          SelectYearPlaceHolder: "Select year",
          CreateNewHREntry: "Create",
          Real: "Real",
          Estimate: "Estimated",
          ChiffreDAffaire: "Turnover per year",
          ChiffreDAffaireparmois: "Turnover per month - Year ",
          Revenueparproduit: "Revenue by product - ",
          Vente: "Sales by product - ",
          ChiffreDAffaireBudget: "Turnover/budget",
          RealEstimate: "Real/estimated",
          thousands: "Thousands",
          budgetatteint: "% Reached budget",
          Pourcentage: "% Percentage",
          depensedessalaires:
            "Expenditure on salaries & subcontractors per year",
          croissancedeseffectifs: "Headcount growth per year",
          pourcentagedesdepenses:
            "% of expenditure on salaries & subcontractors/budget",
          depensedessalairesparstatus:
            "Expenditure on salaries & subcontractors - per status",
          depensedessalairesparmois:
            "Expenditure on salaries & subcontractors per month - Year",
          salary: "Salaries",
          soustraitant: "Sub-contractors",
          totaldepense: "Total expenses",
          persons: "Persons",
          salesdashboard: "Dashboard",
          HumanResourcesdashboard: "Dashboard",
          grossMargins: "Gross margins",
          NoDevis: "No. quote",
          namedevis: "Quote name",
          Editmargin: "Edit margin",
          Orderdetail: "Order detail",
          CustomerName: "Customer name",
          TotalEstimated: "Total estimated",
          TotalInvoiced: "Total invoiced",
          totalhourlycost: "Total hourly cost",
          TotalActual: "Total actual",
          GrossMarginsReport: "Gross margins report",
          CreateNewGross: "Create new gross margin",
          PrintAll: "Print all",
          selectedNoDevis: "Selected No. quote",
          estimatedInvoice: "Estimated/invoiced",
          estimatedActual: "Estimated/actual",
          HourlyRate: "Hourly rate",
          totalHours: "Total hours",
          materialCose: "Material cost",
          otherCost: "Other cost",
          Marges: "Margins",
          estimated: "Estimated",
          invoiced: "Invoiced",
          actual: "Actual",
          noInvoive: "Invoice number",
          return: "Return",
          delete: "Delete",
          deletethetask: "Are you sure you want to delete the gross margin ?",
          Pleaseinputtheorderdetails: "Please input the order detail",
          Pleaseinputthecustomer: "Please input the customer",
          PleaseinputtheDevisName: "Please input the quote's name",
          Pleaseinputthehourlyrate: "Please input the hourly rate",
          Pleaseinputthetotalhours: "Please input the estimated total hours",
          Pleaseinputtheinvoiceno: "Please input the invoice number",
          Pleaseinputthematerialcost: "Please input the material costs invoice",
          Pleaseinputtheothercosts: "Please input the other costs invoice",
          createnewmarginforenterprise: "Create a new gross margin",
          Pleaseinputthecommissionrate: "Please input the commission rate",
          AddActivityType: "Add activity type",
          NewRecurrentExpenseEntry: "Recurring expenses ",
          Activitytypes: "Activity types",
          Activytytypedeletedsuccessfully: "Activyty type deleted successfully",
          ActivityTypesupdatedSuccessfully:
            "Activity type updated successfully",
          AddaTypeIndustries: "Add type industries",
          IndustryTypesupdatedSuccessfully:
            "Industry type updated successfully",
          IndustryTypesdeletedsuccessfully:
            "Industry types deleted successfully",
          AddMarkets: "Add markets ",
          MarketsupdatedSuccessfully: "Market updated successfully",
          Marketsdeletedsuccessfully: "Market deleted successfully",
          AddBusinesspartners: "Add business partners",
          RevenueModelButtonn: "Add revenue model",
          AddProducttt: "Add product",
          AddDepartement: "Add departement",
          Productsdeletedsuccessfully: "Product deleted successfully",
          ProductsupdatedSuccessfullyy: "Product updated successfully",
          AddCustomers: "Add customers",
          CustomersupdatedSuccessfully: "Customer updated successfully",
          Customerdeletedsuccessfully: "Customer deleted successfully",
          EnterpriseDepartmentwasDeletedsuccessfully:
            "Enterprise department was deleted successfully",
          EnterpriseDepartmentAddedsuccessfully:
            "Enterprise department added successfully",
          EnterpriseDepartmenteditedsuccessfully:
            "Enterprise department edited successfully",
          BusinessPartnersdeletedsuccessfully:
            "Business partners was deleted successfully",
          BusinessPartnersupdatedSuccessfully:
            "Business partners edited successfully",
          AddaBusinesspartners: "Add a business partners",
          RevenueModelItemupdatedSuccessfully:
            "Revenue model item updated successfully",
          RevenueModelItemsdeletedsuccessfully:
            "Revenue model items deleted successfully",
          DepartmentsupdatedSuccessfully: "Department updated successfully ",
          departmentdeletedsuccessfully: "Department was deleted successfully",
          EnterpriseDepartments: "Enterprise departments",
          SelectAChartAccount: "Select a chartaccount",
          Add: "Add",
          ParametersforActivytyTypee: "Parameters for activyty type",
          ParametersforBusnissepartners: "Parameters for business partners",
          Parametremarkets: "Parameters for markets",
          ParametersforCustomer: "Parameters for customers",
          Parametersforindustrytypes: "Parameters for industry types",
          ParametersforMargeCapital: "Parameters for marge Capital",
          Parametersformarketingtypes: "Parameters for marketing types",
          Marketingtypes: "Marketing types",
          PaymentTerms: "Payment terms",
          Parametersforpaymentterms: "Parameters for payment terms",
          ParametersforProduct: "Parameters for product",
          Parametersforrevenuemodel: "Parameters for revenue model",
          Parametersforterms: "Parameters for terms",
          Investismentcategory: "Investisment categories",
          investismentcategoryupdatedSuccessfully:
            "Investisment category updated Successfully",
          investismentcategorydeletedsuccessfully:
            "Investisment category was deleted successfully",
          Parametersforinvestissementcategory:
            "Parameters for investisment category",
          Parametersforprojectstatus: "Project statuses",
          ProjectStatus: "Project status",
          projectstatuswassuccessfullydeleted:
            "Project Status was deleted successfully",
          PleasefillinatleastonelabelFrenchorEnglish:
            "Please fill out at least one label in French or English",
          ProjectOperationalPrioritiesshasbeensuccessfullyupdated:
            "The project's operational priorities have been successfully updated",
          Revenutypwassuccessfullydeleted:
            "Revenue type was successfully deleted",
          ProjectOperationalPrioritiesswassuccessfullydeleted:
            "The project's operational priorities have been successfully removed",
          projectstatushasbeensuccessfullyupdated:
            "The project status has been updated successfully",
          Revenutypehasbeensuccessfullyupdated:
            "Revenue type has been updated successfully",
          Youcannotdeletebecauseitisassociated:
            "You can't delete because it is associated",
          Projectacquisitiontypes: "Project acquisition types",
          Projectcomplexitytypes: "Project complexity types",
          Projectacquisitions: "Acquisitions",

          Projectoperationalpriorities: "Operational priority",
          Projectcategorizations: "Project categorizations",

          ProjectdescriptionandContext: "Project description and context",
          benefitsDescription: "Description",
          Scope: "Scope",
          projectComplexity: "Project complexity",
          Projectacquisitionstype: "Acquisition type ",
          Tableofactivities: "Table of activities",
          Addactivity: "Add activity",
          Expensetype: "Expense type",
          ExpenseTypesswassuccessfullydeleted:
            "The expense type has been successfully deleted.",
          ExpenseTypeshasbeensuccessfullyupdated:
            "The expense type has been updated successfully.",
          Recurrent: "Recurrent",
          RecurrentFrequency: "Recurrence frequency",
          SelectRevenueType: "Select revenue type",
          Notrecurrent: "Not recurrent",
          tableofInvestment: "Table of investment",
          PlannedInvestment: "Planned investment",
          Pleaseinputtheprojectnumber: "Please enter the project number",
          Addexpense: "Add expense",
          projectdescription: "Project description",

          PrincipaleProjectobjectivess: "Principale project objective",

          Projecteffortscales: "Effort scales",
          Projectglobalscales: "Global scales",
          Projectbenefits: "Benefits",
          Projectimpacts: "Impacts",
          Projectobjectives: "Project objectives",
          ProjectImpactswassuccessfullydeleted:
            "Project impact have been successfully deleted",
          ProjectEffortScaleswassuccessfullydeleted:
            "Project effort scales was successfully deleted",
          ProjectComplexityTypeswassuccessfullydeleted:
            "Project complexity types was successfully deleted",

          projectcategorizationswassuccessfullydeleted:
            "Project categorizations have been successfully deleted",
          Projectcategorizationshasbeensuccessfullyupdated:
            "Project categorizations have been successfully updated",
          Projectbenefitwassuccessfullydeleted:
            "Project benefit have been successfully deleted",

          Projectbenefitshasbeensuccessfullyupdatedd:
            "Project benefit have been successfully updated",
          Projectacquisitionstypeswassuccessfullydeleted:
            "Project acquisition type have been successfully deleted",
          Projectacquisitionshasbeensuccessfullyupdatedd:
            "Project acquisitions have been successfully updated",
          Projectacquisitionstypeshasbeensuccessfullyupdated:
            "Project acquisitions type  have been successfully updated",
          Projectacquisitionswassuccessfullydeleted:
            "Project acquisitions have been successfully deleted",
          ProjectComplexityTypeshasbeensuccessfullyupdated:
            "Project complexity types was successfully updated",
          ProjectEffortScaleshasbeensuccessfullyupdatedd:
            "Project effort scales was successfully deleted",
          ProjectImpacthasbeensuccessfullyupdatedd:
            "Project impact have been successfully updated",
          ProjectObjectiveswassuccessfullydeleted:
            "The project goals have been successfully deleted",
          Projecteffortgolbalwassuccessfullydeleted:
            " The project effort global have been successfully deleted",
          Projectobjectivesbeensuccessfullyupdatedd:
            "The project objectives have been successfully updated",
          Projecteffortgolbalhasbeensuccessfullyupdatedd:
            " The project effort global have been successfully updated",

          SettingsPagee: "Settings page",
          MarketingTypeupdatedSuccessfully:
            "Marketing type updated successfully",
          Marketingtypedeletedsuccessfully:
            "Marketing type was deleted successfully",
          Addmarge: "Add marge ",
          AddTerms: "Add term",
          Margedeletedsuccessfully: "Marge deleted successfully",
          margeupdatedSuccessfully: "Marge updated successfully",
          Termdeletedsuccessfully: "Term deleted successfully",
          TermsupdatedSuccessfully: "Term updated successfully",
          ParametersforActivytyType: "Parameters for activyty type",
          ParametersforBusnissepartnerss: "Parameters for business partners",
          ParametersIndustrytype: "Parameters for industry type",
          add: "Add",
          department: "Departments",
          entreprisedepartments: "Entreprise departments",
          marketing: "Marketing",
          CreateMarketingg: "Create marketing",
          MarketingSummary: "Marketing summary",
          BackToMarketing: "Back to marketing",
          budgetTotal: "Total budget",
          Marketingexpenses: "Marketing expenses",
          pleaseinputthetypeee: "Please input the type",
          pleaseinputthetotal: "Please input the total",
          pleaseinputthedes: "Please input the description",
          Marketingbudgetdeletedsuccessfully:
            "Marketing budget deleted successfully",
          Reports: "Reports",
          IncomeStatement: "Income statement",
          pret: "Loans",
          VeuillersaisirleMontant: "Please enter the amount",
          VeuillersaisirletitredePrêt: "Please enter the title of loan",
          VeuillersaisirletitredeCreancier:
            "Please enter the title of Creditor",
          Veuillezsaisiruntauxdintéret: "Please enter an interest rate",
          Choisirsonterme: "Choose without term",
          Choisircongédecapital: "Choose capital-free period",
          CapitalfreeperiodSelect: "Please select a capital-free period",
          absencedecongé: "Absence of holiday",
          sixmonths: "6 months",
          twelvemonths: "12 months",
          EditePrett: "Edit a loan",
          marge: "Margin",
          Dashboardsettings: "Dashboard settings",
          TotalDepartement: "Total departments",
          TotalEmployee: "Total employees",
          TotalClient: "Total clients",
          TotalProduct: "Total products",
          Editingmodel: "Editing model",
          EnterpriseNumber: "Enterprise number",
          Selectmodelscomplexity: "Select model's complexity",
          Simple: "Simple",
          Intermediate: "Intermediate",
          Advanced: "Advanced",
          twoweeks: "two weeks",
          Enternewmodelname: "Enter new model name",
          Areyousuretodeletethiscategory:
            "Are you sure to delete this category",
          SubCategory: "Sub category",
          SubSection: "Sub section",
          CashflowSummary: "Cashflow summary",
          InitialCash: "Initial cash",
          Variation: "Variation",
          IntermediateCash: "Intermediate cash",
          AddMinusSection: "Add minus section",
          Attempttoduplicateasection: "Section duplicate attempt error",
          Lineofcreditatthestart: "Line of credit at the start",
          Reimbursementoftheperiod: "Reimbursement of the period",
          Lineofcreditattheend: "Line of credit at the end",
          InterimFinancingatthestart: "Interim Financing at the start",
          InterimFinancingattheend: "Interim Financing at the end",
          Duplicate: "Duplicate",
          DuplicateLatestCashflow: "Duplicate the latest cashflow",
          CreateNewCashflow: "Create new cashflow",
          Period: "Period",
          Suretocancel: "Are you sure to cancel ?",
          simpleModel: "Simple model",
          show: "Show",
          modifyInstanceName: "Modify instance name",
          PleaseInputName: "Please input name",
          ErrorWhileConfirming: "Error while confirming",
          addRow: "Add row",
          deleteRowError: "Error while deleting row",
          pleaseinputname: "Please input name",
          pleaseinputchartofaccount: "Please input chart of account",
          pleaseinputhypotheses: "Please input hypotheses",
          pleaseinput: "Please input",
          SaveValues: "Save values",
          ErrorFetchingData: "Error while fetching data",
          ErrorCreatingCashflow: "Error while creating cashflow",
          ErrorDeletingCashflow: "Error while deleting cashflow",
          CashFlowInstances: "Cashflow instances",
          cashFlowInstances: "Cashflow instance",
          NameUpdatedSuccessfully: "Name updated successfully",
          FailedToUpdateName: "Failed to update name",
          TotaldesactivitésMarketing: "Total marketing activities per year ",
          TotaldesactivitésMarketingparmois:
            "Total Marketing activities per month - ",
          Totaldeschargesparannée: "Total expenses per year ",
          Totaldeschargesannéecourante: "Total current year expenses - ",
          Chargestotalesparmois: "Total expenses per month - ",
          ouvert: "Open",
          attente: "Waiting",
          pendu: "Suspended",
          Totaldesventesbystatus: "Breakdown of opportunities by status - ",
          Totaldesventes: "Total sales in order book - ",
          Totaldesventes1: "Total sales in order book - ",
          Totaldesventes2: "Total Opportunities in the opportunity book - ",
          Totaldesventes3: "Total Opportunities in the opportunity book - ",
          Totaldesventestotal:
            "Total sales for month (order book + opportunity book) - ",
          ExpenseDashboard: "Expenses dashboard",
          MarketingDashboard: "Marketing dashboard",
          InvestmentsDashboard: "Investments dashboard",
          Revenuedashboard: "Revenu dashboard",
          Enterprise: "Enterprise",
          ModelsName: "Model's Name",
          Areyousuretodeletethissection: "Are you sure to delete this section",
          createdAt: "Created at",
          Createcashflow: "Create new model",
          NewModel: "New model",
          Selectanenterprise: "Select an enterprise",
          Modelcreatedsuccessfully: "Model created successfully",
          Customization: "Customization",
          Fromamodel: "From a model",
          History: "History",
          Selectanexistingmodel: "Select an existing model",
          choosecomplexity: "choose complexity",
          InitialPart: "Initial part",
          InflowPart: "Inflow part ",
          selectGLNumber: "Select gl number",
          accountname: "Account name",
          InflowTotal: "Inflow total",
          InterimFinancing: "Interim financing",
          InflowGlobalTotal: "Inflow global total",
          Section: "Section",
          OutflowTotal: "Outflow total",
          deleteStatement: "Delete Statement",
          Differencebetweeninflowandoutflow:
            "Difference between inflow and outflow",
          FinalCash: "FinalCash ",
          SummaryPart: "Summary part ",
          DuplicatechartAccounterror: "Duplicate chart account ",
          Modelcreatedwithsuccess: "Model created with success",
          ModelCreation: "Model creation",
          Allfieldsmustbefilled: "All fields must be filled",
          Backtomoddelsslist: "Back to moddels's list",
          Previous: "Previous",
          Next: "Next",
          Editingmodel: "Editing model",
          EnterpriseNumber: "Enterprise number",
          pleaseselectEnterpriseNumber: "Please select enterprise number",
          Selectmodelscomplexity: "Select model's complexity",
          Simple: "Simple",
          Intermediate: "Intermediate",
          Advanced: "Advanced",
          twoweeks: "two weeks",
          Enternewmodelname: "Enter new model name",
          Areyousuretodeletethiscategory:
            "Are you sure to delete this category",
          Modeldeletedsuccessfully: "Model deleted successfully",
          SubCategory: "New sub category",
          SubSection: "New sub section",
          CashflowSummary: "Cashflow summary",
          InitialCash: "Initial cash",
          Variation: "Variation",
          IntermediateCash: "Intermediate cash",
          AddMinusSection: "Add minus section",
          Attempttoduplicateasection: "Section duplicated attempt error",
          Lineofcreditatthestart: "Line of credit at the start",
          Reimbursementoftheperiod: "Reimbursement of the period",
          Lineofcreditattheend: "Line of credit at the end",
          InterimFinancingatthestart: "Interim Financing at the start",
          InterimFinancingattheend: "Interim Financing at the end",
          Duplicate: "Duplicate",
          DuplicateLatestCashflow: "Duplicate the latest cashflow",
          CreateNewCashflow: "Create new cashflow",
          Period: "Period",
          Suretocancel: "Are you sure to cancel ?",
          simpleModel: "Simple model",
          show: "Show",
          modifyInstanceName: "Modify instance name",
          PleaseInputName: "Please input name",
          ErrorWhileConfirming: "Error while confirming",
          addRow: "Add row",
          deleteRowError: "Error while deleting row",
          pleaseinputname: "Please input name",
          pleaseinputchartofaccount: "Please input chart of account",
          pleaseinputhypotheses: "Please input hypotheses",
          pleaseinput: "Please input",
          SaveValues: "Save values",
          ErrorFetchingData: "Error while fetching data",
          ErrorCreatingCashflow: "Error while creating cashflow",
          ErrorDeletingCashflow: "Error while deleting cashflow",
          NameUpdatedSuccessfully: "Name updated successfully",
          FailedToUpdateName: "Failed to update name",
          Totaldeschargesparannée: "Total expenses per year ",
          Totaldeschargesannéecourante: "Total current year expenses - ",
          ChargesparGLNumber: "Expenses by GL number - ",
          Chargestotalesparmois: "Total expenses per month - ",
          AssignmentTracking: "Assignment tracking",
          Week: "Week",
          TwoWeeks: "Two weeks",
          Month: "Month",
          pourglAccountID: "For GLaccountId",
          AddCategory: "Add category",
          AddSection: "Add section",
          CashflowModels: "Cash Flow Models",
          Step1: "Step 1",
          Step2: "Step2",
          InitialCash: "Intial Cash",
          Inflow: "Inflow",
          Outflow: "Outflow",
          Summary: "Summary",
          ModelCreatedSuccessfully: "Model Created Successfully",
          EditModelInfo: "Edit Model Info",
          CategoryWasUpdatedSuccessfully: "Category was updated successfully",
          SectionWasUpdatedSuccessfully: "Section was updated successfully",
          AnErrorOccurredWhileAddingTheSection:
            "An Error Occurred While Adding The Section",
          AnErrorOccurredWhileAddingTheCategory:
            "An Error Occurred While Adding The Category",
          CategoryDeletedSuccessfully: "Category Deleted Successfully",
          AnErrorOccurredWhileDeletingTheCategory:
            "An Error Occurred While Deleting The Category",
          ModelUpdatedSuccessfully: "Model Updated Successfully",
          AreYouSureYouWantDelteThisModel:
            "Are you sure you want to do away with this model? It won't affect existing  instances.",
          LineOfCreditAtTheStart: "Line Of Credit At The Start",
          salesBudgetByMonth: "Sales budgets per Month",
          Year1: "Year +1",
          Year2: "Year +2",
          Year3: "Year +3",
        },
      }, // TODO: Heres
      fr: {
        translation: {
          Entercontext: "Enter le contexte",
          SalesBudgetDeletedSuccessfully:
            "Budget des ventes supprimé avec succès",
          Year1: "Année +1",
          Year2: "Année +2",
          Year3: "Année +3",

          FailedToDeleteSalesBudget:
            "Échec de la suppression du budget des ventes",
          FailedToConfirmBudgetOnServer:
            "Échec de la confirmation du budget sur le serveur",
          SalesBudgetUpdatedSuccessfully:
            "Budget des ventes mis à jour avec succès",
          FailedToUpdateSalesBudget:
            "Échec de la mise à jour du budget des ventes",
          BudgetConfirmedSuccessfully: "Budget confirmé avec succès",
          deletesalesbudget: "Suppression du budget des ventes",
          PleaseSelectAyearToDisplayTheData:
            "Veuillez sélectionner une année pour afficher les données",
          NoData: "Pas de données à afficher",
          Difference: "Différence",
          Percentage: "Pourcentage (%)",
          OrderbookDate: "Date du carnet de commandes",
          OrderbookName: "Nom du carnet de commandes",
          PerformanceMonitoring: "Suivi de la performance",
          BackToSalesBudgets: "Retour au budget des ventes",
          unlink: "Dissocier",
          unlinkedsuccessfully: "Dissocier avec succé",
          linkedsuccessfully: "Associer avec succé",
          Contractual: "Sous-traitant",
          SuccessfullyRemovedRolesFromAccount:
            "Suppression réussie des rôles du compte",
          Alldepartments: "Tous les départements",
          FinancialstatementCategory: "Catégorie d'état financier",
          Addgroup: "Ajouter groupe",
          FinancialstatementCategories: "Catégories d'état financier",
          AddNewCategory: "Ajouter une nouvelle catégorie",
          CategoryCreatedSuccessfully: "Catégorie créée avec succès",
          SomethingWentWrongWhileCreatingCategory:
            "Un problème s'est produit lors de la création de la catégorie",
          pleaseinputthefinancialStatementClass:
            "Veuillez sélectionner la classe d'état financier",
          financialStatementClass: "Classe d'état financier",
          Financialratio: "Ratio financier",
          pleaseinputthelabelinEnglish: "Veuillez saisir le libellé en anglais",
          pleaseinputthelabelinFrench: "Veuillez saisir le libellé en français",
          labelFr: "Libellé en français",
          labelEn: "Libellé en anglais",
          SomethingWentWrongWhileRecuperingCategories:
            "Erreur lors de la récupération des catégories",
          SelectClass: "Sélectionner une classe",
          PleaseSelectClassToDisplayTheCategories:
            "Veuillez sélectionner une classe pour afficher les catégories",
          IncludesWhiteLabeling: "Comprend le marquage blanc",
          PleaseSelectEmployee: "Veuillez sélectionner un employé",
          PleaseSelectPaymentTerm: "Veuillez sélectionner un terme de paiement",
          PleaseSelectProduct: "Veuillez sélectionner un produit",
          NoSocialAdvantages: "Pas d'avantages sociaux",
          PleaseAddAPaymentTerm: "Veuillez ajouter un terme de paiement",
          AddPaymentTerm: "Ajouter un terme de paiement",
          PaymentTermUpdatedSuccessfully:
            "Terme de paiement mis à jour avec succès",
          FailedToAddPaymentTerm: "Échec de l'ajout du terme de paiement",
          PaymentTermAddedSuccessfully: "Terme de paiement ajouté avec succès",
          PaymentTermDeletedSuccessfully:
            "Terme de paiement supprimé avec succès",
          FailedTodeletePaymentTerm:
            "Échec de la suppression du terme de paiement",
          PasswordSpecialCharError:
            "Le mot de passe doit contenir au moins 2 caractères spéciaux",
          PasswordsDoNotMatch: "Les mots de passe ne correspondent pas",
          PleaseEnterPassword: "Veuillez saisir le mot de passe",
          PleaseConfirmPassword: "Veuillez confirmer le mot de passe",
          SalesBudgetSummary: "Résumé du budget des ventes",
          GoBackToSalesBudget: "Retour au budget des ventes",
          SalesBudgetDetails: "Budget des ventes",
          marketingType: "Type de marketing",
          Pleaseselectamarketingtype:
            "Veuillez sélectionner un type de marketing",
          selectmarketingtype: "Sélectionnez le type de marketing",
          PleaseentermarketingTypelabel: "Veuillez saisir un type de marketing",
          SalesBudget: "Budget des ventes",
          LoadingData: "Chargement des données...",
          ErrorMessage:
            "Il existe déjà ce compte GL avec le numéro que vous avez fourni. Veuillez utiliser un autre numéro.",
          GenericErrorMessage:
            "Une erreur inattendue s'est produite. Veuillez réessayer plus tard.",
          NoSelectedyear:
            "Aucune année sélectionnée. Veuillez sélectionner une année avant le de dupliqur.",
          LineOfCreditAtTheStart: "Ligne de crédit au départ",
          Difference: "Différence",
          AreYouSureYouWantDelteThisModel:
            "Êtes-vous sûr de supprimer ce modèle ? Cela n'affectera pas les flux de trésorerie existants.",
          AreYouSureToDeleteThisChartAccount:
            "Attention ! Vous êtes sur le point de supprimer ce plan comptable. Cette action est irréversible et entraînera la perte définitive de toutes les données qui y sont liées. Êtes-vous absolument certain de vouloir continuer ?",
          ChartAccountDeletedSuccessfully:
            "Plan comptable supprimé avec succès",
          FailedToDeleteChartAccount:
            "Échec de la suppression du plan comptable",
          ErrorDeletingChartAccount:
            "Impossible de supprimer le plan de compte car il est lié à d'autres données ou transactions. Veuillez supprimer toutes les données et transactions liées avant de supprimer le plan de compte.",
          Yearly: "Annuel",
          SuccessfullyRemovedRolesFromAccount:
            "Suppression réussie des rôles du compte",
          SuccessfullyAddedRolesToAccount:
            "Rôles ajoutés avec succès au compte",
          PleaseSelectAtLeastOneRole: "Veuillez sélectionner au moins un rôle",
          FailedToAddRolesToAccount: "Échec de l'ajout de rôles au compte",
          FailedToAddRoleToAccount: "Échec de l'ajout de rôle au compte",
          FailedToRemoveRoleFromAccount:
            "Échec de la suppression du rôle du compte",
          FailedToChangePassword: "Le changement de mot de passe a échoué",
          PasswordChangedSuccessfully: "Mot de passe changé avec succès",
          Password: "Mot de passe",
          CashFlowModel: "Modèles",
          CashFlow: "Flux de trésorerie",
          AddNewFinancialstatementType: "Ajouter type d'état financier",
          FinancialstatementType: "Type d'état financier",
          ModelUpdatedSuccessfully: "Mise à jour réussie du modèle",
          AnErrorOccurredWhileDeletingTheCategory:
            "Une erreur s'est produite lors de la suppression de la catégorie",
          CategoryDeletedSuccessfully: "Catégorie supprimée avec succès",
          SectionWasUpdatedSuccessfully:
            "La section a été mise à jour avec succès",
          AnErrorOccurredWhileAddingTheCategory:
            "Une erreur s'est produite lors de l'ajout de la catégorie",
          AnErrorOccurredWhileAddingTheSection:
            "Une erreur s'est produite lors de l'ajout de la section",
          CategoryWasUpdatedSuccessfully:
            "La catégorie a été mise à jour avec succès",
          EditModelInfo: "Modifier les informations du modèle",
          ModelCreatedSuccessfully: "Modèle créé avec succès",
          InitialCash: "Cash Initial",
          Inflow: "Encaissements",
          Outflow: "Décaissement",
          Summary: "Résumé",
          Step1: "Etape 1",
          Step2: "Etape 2",
          Createcashflow: "Créer Nouveau Modèle",
          NameofTheOrderBook: "Le nom du carnet des commandes",
          NameofTheOpportunityBook: "Le nom du carnet des opportunitées",
          Errorwhiledeletingopportunitybook:
            "Erreur lors la suppression du carnet des opportunités",
          opportunitybookcreationerror:
            "erreur lors de la création du carnet des opportunités",
          opportunitybookerror:
            "erreur lors de la création du carnet des opportunités",
          EnternameOpportunity: "Saisir le nom du carnet d'opportunité",
          orderbookcreationerror:
            "erreur lors de la création du carnet de commandes",
          Entername: "Saisir le nom du carnet de commandes",
          ChartAccountCreatedSuccessfullyForTheyear:
            "Plan comptable créé avec succès pour l'année",
          GoBackToRevenue: "Revenir aux revenus",
          ErrorUpdatingHyphothesis:
            "Erreur lors de la mise à jour de l'hypothèse",
          HyphothesisUpdatedSuccessfully: "Hypothèse mise à jour avec succès",
          GLAccountUpdatedSuccessfully: "Compte GL mis à jour avec succès",
          ErrorUpdatingGLAccount: "Erreur lors de la mise à jour du compte GL",
          ErrorDeletingExpense: "Erreur lors de la suppression de la dépense",
          ExpenseDeletedSuccessfully: "Dépense supprimée avec succès",
          deleterowHypo: "Voulez-vous supprimer la ligne séléctionnée ?",
          HyphothesisCreatedSuccessfully: "Hypothèse créée avec succès",
          ErrorCreatingHyphothesis: "Erreur lors de la création de l'hypothèse",
          HyphothesisDeletedSuccessfully: "Hypothèse supprimée avec succès",
          ErrorDeletingHyphothesis:
            "Erreur lors de la suppression de l'hypothèse",
          Stepaddedsuccessfully: "Étape ajoutée avec succès",
          Stepupdatedsuccessfully: "Étape mise à jour avec succès",
          ErrorWhileUpdatingStep: "Erreur lors de la mise à jour de l'étape",
          Stepdeletedsuccessfully: "Étape supprimée avec succès",
          Anerroroccuredpleasetryagain:
            "Une erreur s'est produite. Réessayez Plus tard",
          Activityupdatedsuccessfully: "Activité mise à jour avec succès",
          ErrorWhileUpdatingActivity:
            "Erreur lors de la mise à jour de l'activité",
          Activityaddedsuccessfully: "Activité ajoutée avec succès",
          ErrorWhileAddingActivity: "Erreur lors de l'ajout de l'activité",
          TargetAddedSuccessfully: "Cible ajoutée avec succès",
          AnErrorOccurredWhileAddingTheTarget:
            "Une erreur s'est produite lors de l'ajout de la cible",
          AnErrorOccurredWhileEditingTheTarget:
            "Une erreur s'est produite lors de la modification de la cible",
          TargetDeletedSuccessfully: "Cible supprimée avec succès",
          AnErrorOccurredWhileDeletingTheTarget:
            "Une erreur s'est produite lors de la suppression de la cible",
          ThisTargetContainsUnfinishedSteps:
            "Cette cible contient des étapes inachevées",
          EmployeeDeletedSuccessfully: "Employé supprimé avec succès",
          ErrorDeletingEmployee: "Erreur lors de la suppression de l'employé",
          ChangesSavedSuccessfully: "Modifications enregistrées avec succès",
          EmployeeAddedSuccessfully: "Employé ajouté avec succès",
          ErrorAddingEmployee: "Erreur lors de l'ajout de l'employé",
          MarketingUpdatedSuccessfully: "Marketing mis à jour avec succès",
          SomethingWentWrongWhileUpdatingMarketing:
            "Quelque chose s'est mal passé lors de la mise à jour du marketing",
          GoBackToAssets: "Revenir aux actifs",
          MarketingNotFoundForThisYear:
            "Marketing introuvable pour cette année",
          ErrorFetchingMarketing: "Erreur lors de la récupération du Marketing",
          MarketingCreatedSuccessfully: "Marketing créé avec succès",
          SomethingWentWrongWhileCreatingMarketing:
            "Erreur lors de la création du Marketing",
          Investmentssavedsuccessfully:
            "Investissements sauvegardés avec succès",
          Investmentsupdatedsuccessfully: "Investissements modifié avec succès",
          GoBackToLiabilities: "Revenir au passif",
          GoBackToExpenses: "Revenir aux dépenses",
          GoBackToEquity: "Revenir à l'avoir",
          ChargesparDescription: "Charges par description - ",
          pleaseinputtheyear: "Veuillez entrer l'année",
          YearShouldNotBeLessThan0: "L'année ne doit pas être inférieure à 0",
          RevenueDetail: "Détails des revenus",
          ModelCreatedSuccessfully: "Modèle créé avec succès",
          AreYouSureToDeleteEmployee:
            "Voulez-vous vraiment supprimer l'employé ?",
          AreYousSureDeleteThisCategory:
            "Voulez-vous vraiment supprimer la catégorie séléctionnée ?",
          Savehanges: "Enregistrer les modifications",
          pourglAccountID: "Pour le compte GL",
          Yes: "Oui",
          No: "Non",
          AreYouSureToDeleteTheEmployee:
            "Voulez-vous supprimer l'employé séléctionné ?",
          PleaseSelectDepartmentAndYear:
            "Veuillez sélectionner Le département et une année",
          PleaseSelectEmployee: "Sélectionnez l'employé",
          ErrorWhileSavingChanges:
            "Erreur lors de la sauvegarde des changements",
          PleaseSelectDepartment: "Veuillez sélectionner le département",
          PleaseSelectYear: "Veuillez sélectionner une année",
          ConfirmBudget: "Voulez-vous confirmer le budget",
          Selectlanguage: "Sélectionner langue",
          pleaseinputlanguage: "Veuillez sélectionner la langue",
          pleaseselectcurrency: "Veuillez Sélectionner la devise",
          pleaseselectplan: "Veuillez sélectionner le plan",
          Choosefrequency: "Choisir la fréquence",
          Selectcurrency: "Sélectionner la devise",
          Selectintegrator: "Sélectionner l'intégrateur",
          Selectplan: "Sélectionner le plan",
          Dashboard: "Tableau de bord",
          MarginUpdatedSuccessfully: "Marge mise à jour avec succès",
          ErrorWhileUpdatingMargin: "Erreur lors de la mise à jour de la marge",
          MargeDeletedSuccessfully: "Marge supprimée avec succès",
          ErrorWhileDeletingTheMarge:
            "Erreur lors de la suppression de la marge",
          MargeAddedSuccessfully: "Marge ajoutée avec succès",
          ErrorWhileAddingTheMarge: "Erreur lors de l'ajout de la marge",
          Standard: "Standard",
          Personalized: "Personnalisé",
          Period: "Période",
          from: "De",
          to: "À",
          months: "Mois",
          displaydetails: "Afficher détails",
          summaries: "Sommaire",
          Documentsizenorientation: "Taille et orientation du document",
          orientation: "Orientation",
          size: "Taille",
          checkall: "Cocher tout",
          generatereport: "Générer rapport",
          savereportoptions: "Enregistrer les options du rapport",
          other: "Autres",
          currency: "Devise",
          languages: "Langues",
          name: "Nom",
          entername: "Entrer un nom",
          Landscape: "Paysage",
          Portrait: "Portrait",
          letter: "Lettre",
          Totals: "Totaux",
          Averages: "Moyennes",
          Glnumber: "Numero GL",
          Client: "Client",
          pleaseselectamonth: "Veuillez sélectionner un mois",
          selectmonth: "Sélectionner un ou plusieurs mois",
          selectoption: "Sélectionner une option",
          namerepport: "Nommer le rapport",
          Type: "Type",
          AnnualBudget: "Budget annuel",
          selectsize: "Sélectionner la taille",
          Status: "Statut",
          OpportunityExpectedRevenueTotal: "Revenu attendu de l'opportunité",
          FromAnOpportunity: "De l'opportunité",
          OrderRevenueTotal: "Total des revenus de commande",
          ExpectedRevenueTotal: "Total des revenus attendus",
          Expectedtotal: "Total attendus",
          TotalRevenue: "Total des revenues",
          GIFI: "GIFI",
          AnerrorhasoccuredWhileUpdatingtheLoan:
            "Erreur lors de la mise à jour du prêt",
          ErrorAddingLoan: "Erreur lors de l'ajout du prêt",
          LoanUpdatedSuccessfully: "Prêt mis à jour avec succès",
          LoanAddedSuccessfully: "Prêt ajouté avec succès",
          ErrorFetchingLoans: "Erreur lors de la récupération des Prêts",
          ErrorDeletingLoan: "Erreur lors de la suppression du prêt",
          LoanDeletedSuccessfully: "Prêt supprimé avec succès",
          ParametersforMarketingType: "Paramètres pour le type de marketing",
          Updatedsuccessfully: "Mis à jour avec succès",
          Pleaseenterlabel: "Veuillez saisir le label",
          Veuillersaisirlabel: "Veuillez saisir le label",
          addTitle: "Ajouter un Titre",
          TitlesUpdatedSuccessfully: "Titres mis à jour avec succès",
          ErrorDeletingTitle: "Erreur lors de la suppression du titre",
          ErrorFetchingTitles: "Erreur lors de la récupération des titres",
          Titledeletedsuccessfully: "Titre supprimé avec succès",
          Personalized: "Personnalisé",
          Personalizedrapport: "Rapport personnalisé",
          returntoreportsection: "retourner à la section du rapport",
          Reportdeletedsuccessfully: "Rapport supprimé avec succès",
          Reportgeneratedsuccessfully: "Rapport généré avec succès",

          Failedtogeneratereportbecauseithasnocustomdata:
            "Échec de la génération du rapport car n'a aucune donnée personnalisée",
          PleaseinputthePhoneNumber: "Veuillez saisir le Numéro de Téléphone",
          NotYetDeparted: "Pas encore parti",
          PleaseSelectATitle: "Veuillez sélectionner un titre",
          TotalInvestmentActivitiesPerMonth:
            "Total des activités d'investissement par mois",
          TotalInvestmentPerYear: "Total des investissements par année",
          InvestmentActivitiesByCategory:
            "Activités d'investissement par catégorie",
          AverageAgeErrorMessage: "Sélectionnez l'âge",
          deleteStatement: "Supprimer revenu",
          SlectATitle: "Sélectionner un titre",
          OutflowPart: "Partie sortante",
          CommonPart: "Partie commune",
          Complexity: "Complexité",
          Frequency: "Fréquence",
          PleaseChooseDateJoin: "Veuillez choisir la Date de début",
          PleaseinputtheEmail: "Veuillez saisir l'email",
          PleaseinputthePhoneNumber: "Veuillez saisir le numéro de téléphone",
          PleaseInputYourLastName: "Veuillez saisir le nom de famille",
          AnErrorOccurredWhileCreatingTheIntegratorAccount:
            "Une erreur s'est produite lors de la création du compte de l'intégrateur",
          FailedtoCreateIntegrator: "Erreur lors la création de l'intégrateur",
          IntegratorCreatedSuccessfully: "Intégrateur créé avec succès",
          NoEmployeesAssociatedWithSelectedTitle:
            "Aucun employé associé au titre sélectionné",
          EmployeeAccountCreatedSuccessfully: "Compte employé créé avec succès",
          UpdatePlan: "Mettre à jour le plan",
          ErrorFetchingEmployeesForSelectedTitle:
            "Erreur lors de la récupération des employés pour le titre sélectionné",
          UnselecteAll: "Déselectionner tout",
          SelectAll: "Sélectionner tout",
          ErrorCreatingEmployeeAccount: "Erreur lors de la création du compte",
          ErrorFetchingIntegratorAccounts:
            "Erreur lors de la récupération des comptes d'intégrateur",
          ErrorFetchingEmployeeAccounts:
            "Erreur lors de la récupération des comptes des employés",
          IntegratorAccountUpdated: "Compte d'intégrateur mis à jour",
          ErrorCreatingRole: "Erreur lors de la création du rôle",
          SaveAccess: "Sauvegarder",
          CanBeSeenByIntegrator: "Vu par l'intégrateur",
          CanBeSeenByClient: "Vu par le client",
          AccessSavedSuccessfully: "Accès sauvegardé avec succès",
          Areyousuretodeletethisone: "Êtes-vous sûr de supprimer celui-ci",
          Currencies: "Devises",
          ShortLabel: "Abréviation",
          SaveCurrency: "Enregistrer la devise",
          CancelEditing: "Annuler l'édition",
          EditCurrency: "Modifier la devise",
          AddNewCurrency: "Ajouter une Devise",
          CurrencyAddedSuccessfully: "Devise ajoutée avec succès",
          CurrencyUpdatedSuccessfully: "Devise mise à jour avec succès",
          ErrorSavingCurrencies: "Erreur lors de l'enregistrement des devises",
          Languages: "Langues",
          AddNewLanguage: "Ajouter une Langue",
          ErrorFetchingLanguages: "Erreur lors de la récupération des langues",
          LanguageAddedSuccessfully: "Langue ajoutée avec succès",
          LanguageUpdatedSuccessfully: "Langue mise à jour avec succès",
          ErrorSavingLanguages: "Erreur lors de l'enregistrement des langues",
          SaveLanguage: "Sauvegarder la Langue",
          EditLanguage: "Modifier la langue",
          Security: "Sécurité",
          Plans: "Plans",
          PlanUpdated: "Plan mis à jour avec succès",
          AnErrorOccuredWhileUpdatingPlan:
            "Une erreur est survenue lors de la mise à jour du plan",
          RoleVisibility: "Visibilité du rôle",
          seenByIntegrator: "Vu par l'intégrateur",
          seenByClient: "Vu par le client",
          RoleDeactivated: "Rôle désactivé",
          AnErrorOccuredWhileactivatingRole:
            "Une erreur s'est produite lors de l'activation du rôle",
          AnErrorOccuredWhileDeactivatingRole:
            "Une erreur s'est produite lors de la désactivation du rôle",
          RoleActivatedSuccessfully: "Rôle activé avec succès",
          EditPlan: "Modifier le plan",
          IncludingWhiteLabeling: "Incluant le marquage blanc",
          IncludedSections: "Sections incluses",
          PlanDoesNotIncludeAnySections:
            "Le plan ne contient aucune section/sous-section",
          planCreatedSuccessfully: "Plan créé avec succès",
          PlanSuccessfullyDeleted: "Le plan a été supprimé avec succès",
          FailedToDeletePlan: "Échec de la suppression du plan",
          ErrorDeletingPlan: "Erreur lors de la suppression du plan",
          OurPlans: "Nos plans",
          StandardUsers: "Utilisateurs standard",
          CreatePlan: "Créer un nouveau plan",
          IncludesWhiteLableing: "Inclut le marquage Blanc",
          EnterStandardUsers: "Entrez le nombre d'utilisateurs standard",
          PlanModules: "Modules du plan",
          EnterCostPerExtraUser:
            "Entrez le coût par utilisateurs supplémentaires",
          PleaseInputTheCostPerExtraUser:
            "Veuillez saisir le coût par utilisateur supplémentaire",
          CostPerExtraUser: "Coût par utilisateurs",
          CurrentPrice: "Prix actuel",
          PleaseSelectTheFrequency: "Veuillez sélectionner la fréquence",
          PleaseInputTheStandardUsers:
            "Veuillez saisir le nombre d'utilisateurs standard",
          SelectFrequency: "Choisissez la fréquence",
          EnterDescription: "Entrez la description",
          Frequency: "Fréquence",
          standardPrice: "Prix standard",
          PleaseInputTheLabel: "Veuillez saisir le Libellé",
          EnterStandardPrice: "Entrez le prix standard",
          PlanGeneralInfos: "Informations générales du plan",
          CreateANewPlan: "Créer un nouveau plan",
          IntegratorName: "Nom de l'intégrateur",
          Desactivate: "Désactiver",
          Activated: "Activé",
          AccountStatus: "Statut du compte",
          IntegratorAccountactivated: "Compte d'intégrateur activé avec succès",
          IntegratorAccountUpdated:
            "Compte d'intégrateur mis à jour avec succès",
          IntegratorAccountDeactivated:
            "Compte d'intégrateur désactivé avec succès",
          NotYetConnected: "Pas encore connecté",
          EmployeeAccountUpdated: "Compte employé mis à jour",
          AnErrorOccuredWhileUpdatingEmployeeAccount:
            "Une erreur est survenue lors de la mise à jour du compte de l'employé",
          NoRoleAssociated: "Aucun rôle associé",
          UpdateEmployeeAccount: "Mettre à jour le compte de l'employé",
          CreateAnewUser: "Créer un nouveau utilisateur",
          EmployeeSelection: "Sélection de l'employé",
          Accounts: "Comptes",
          Users: "Utilisateurs",
          CreateAnUser: "Créer un utilisateur",
          Employees: "Employés",
          NewUser: "Nouveau utilisateur",
          PleaseSelectAnEmployee: "Veuillez sélectionner un employé",
          PleaseEnterAnEmail: "Veuillez saisir un email",
          PleaseEnterAPhoneNumber: "Veuillez saisir un numéro de téléphone",
          PleaseEnterAPassword: "Veuillez saisir un mot de passe",
          PleaseConfirmYourPassword: "Veuillez confirmer votre mot de passe",
          PleaseSelectARoleOrMore: "Veuillez sélectionner un rôle ou plus",
          YourPasswordHasBeenGenerated:
            "Le mot de passe a été généré. Veuillez enregistrer ce mot de passe dans un endroit sûr. Vous ne pourrez pas le récupérer si vous le perdez.",
          GoBack: "Retour",
          GeneratePassword: "Générer un mot de passe",
          CreateAnIntegrator: "Créer un intégrateur",
          Integrators: "Intégrateurs",
          FirstName: "Prénom",
          EnterFirstName: "Entrez le prénom",
          EnterLastName: "Entrez le nom de famille",
          SelectDate: "Sélectionnez la date",
          EnterEmail: "Entrez l'email",
          EnterPhoneNumber: "Entrez le numéro de téléphone",
          EnterPassword: "Entrez le mot de passe",
          ConfirmPassword: "Confirmez le mot de passe",
          LastName: "Nom de famille",
          DateJoined: "Date d'adhésion",
          DepartureDate: "Date de départ",
          Email: "Email",
          PhoneNumber: "Numéro de téléphone",
          LastLogin: "Dernière connexion",
          IntegratorInfos: "Informations sur l'intégrateur",
          AccountInfos: "Informations sur le compte",
          general: "Général",
          AccessControl: "Contrôle d'accès",
          ManageSections: "Gestion des sections",
          ManageAccess: "Gestion des accès",
          ManageRoles: "Gestion des Rôles",
          ManageTitles: "Gestion des Titres",
          ManageAccounts: "Gestion des Comptes",
          AddNewAccount: "Ajouter un nouveau compte",
          CreateAccount: "Créer un compte",
          AddNewRole: "Ajouter un nouveau rôle",
          SelectRole: "Sélectionner un rôle",
          ChooseRoleToManageAccess: "Choisir un rôle pour gérer l'accès",
          RoleAlreadyExists: "Le rôle existe déjà",
          RoleCreatedSuccessfully: "Le rôle a été créé avec succès",
          ErrorUpdatingRole: "Erreur lors de la mise à jour du rôle",
          AddNewManager: "Ajouter un nouveau gestionnaire",
          CreateRole: "Créer un rôle",
          PleaseEnterRoleName: "Veuillez entrer le nom du rôle",
          RoleNames: "Noms de rôle",
          FilterWithParentSection: "Filtrer avec la section parent",
          PhoneNumber: "Numéro de téléphone",
          PleaseEnterTheEmail: "Veuillez entrer l'email",
          ChangePassword: "Changer le mot de passe",
          UpdateAccount: "Mettre à jour le compte",
          AccountDetails: "Détails du compte",
          Update: "Mise à jour",
          CurrentPassword: "Mot de passe actuel",
          NewPassword: "Nouveau mot de passe",
          AddRoleToAccount: "Ajouter un rôle au compte",
          RemoveRoleFromAccount: "Supprimer le rôle du compte",
          NA: "N/A",
          PleaseEnterThePhoneNumber: "Veuillez entrer le numéro de téléphone",
          PleaseSelectAManager: "Veuillez sélectionner un gestionnaire",
          PleaseEnterThePassword: "Veuillez entrer le mot de passe",
          PleaseSelectAtLeastOneRole: "Veuillez sélectionner au moins un rôle",
          RoleName: "Nom du rôle",
          EditRole: "Modifier le rôle",
          SaveRole: "Enregistrer le rôle",
          CancelEdit: "Annuler la modification",
          RoleUpdatedSuccessfully: "Le rôle a été mis à jour avec succès !",
          AddNewSection: "Ajouter une nouvelle section",
          monthbusinessdays: "Mois jours ouvrés",
          AddSection: "Ajouter une section",
          SectionName: "Nom de la section",
          ParentSection: "Section parent",
          Subsections: "Sous-sections",
          AddSubsection: "Ajouter une sous-section",
          AddSubsectionTo: "Ajouter une sous-section à",
          CancelSubsection: "Annuler la sous-section",
          SubsectionName: "Nom de la sous-section",
          ConfirmDeleteSection: "Confirmer la suppression de la section",
          NoSubSections: "Aucune sous-section disponible",
          DeleteSection: "Supprimer la section",
          SelectParentSection: "Sélectionner la section parent",
          CheckAll: "Tout cocher",
          Insert: "Insertion",
          Read: "Lecture",
          home: "Accueil",
          UpdateCompany: "Modifier l'entreprise",
          textupdate:
            "Veuillez remplir les champs obligatoires ci-dessous et cliquer sur le bouton Enregistrer pour mettre à jour les informations de votre entreprise",
          generalinf: "Informations générales",
          companyname: "Nom de la Société",
          address: "Adresse",
          country: "Pays",
          province: "Province",
          city: "Ville",
          postalcode: "Code postal",
          foundingdate: "Date de fondation",
          loadingcompanyinformation:
            "Chargement des informations sur l'entreprise...",
          Loadingfinished: "Chargement terminé",
          orderbook: "Carnet de commande",
          dashboard: "Tableau de bord",
          sales: "Ventes",
          nameproduct: "Nom du produit",
          CitySelect: "Veuillez sélectionner une ville",
          Companydeleted: "Entreprise supprimée avec succès !",
          nodata: "Aucune donnée trouvée",
          logout: "Se déconnecter",
          company: "Ajouter une entreprise",
          select: "Sélectionner et accéder aux informations sur l'entreprise :",
          Targetcustomers: "Clientèle cible",
          Market: "Marché",
          Themaincustomers: "Clients",
          Revenuemodel: "Modèles de revenus",
          PleaseInputTheStartYear: "Veuillez saisir l'année de début",
          Businesspartners: "Partenaires d'affaires",
          Businessnumber: "Numéro d'entreprise",
          Typeofindustry: "Type d'industrie",
          BookTotal: "Total du Carnet",
          type: "Type",
          Postalcode: "Code postal",
          Foundingdate: "Date de fondation",
          Yearenddate: "Date de fin d'exercice",
          Estimatedannualtaxrate: "Taux d'imposition annuel estimé (%)",
          Numberofemployees: "Nombre d'employés",
          CreateanewStrategictargets:
            "Créer de nouveaux objectifs stratégiques",
          editcompany: "Modifier les informations",
          deletecompany: "Supprimer l'entreprise",
          deletecompanytext: "Êtes-vous sûr de vouloir supprimer",
          // addnewmanager: "Ajouter un nouveau gestionnaire",
          PleaseInputTheYearsInterval: "Veuillez saisir l'intervalle d'années",
          pleaseenteranumberbetween0and100:
            "Veuillez entrer un nombre entre 0 et 100",
          valuecannotbelessthan0: "La valeur ne peut pas être inférieure à 0",
          addnewholder: "Ajouter un nouveau titulaire",
          selectcustomer: "Sélectionner un client",
          selectproduct: "Sélectionner un produit",
          selectdate: "Sélectionner une date",
          addtolist: "Ajouter à la liste",
          pleaseselectthestrategictarget:
            "Veuillez sélectionner la cible stratégique",
          addtitles: "Ajouter un titre",
          valuesaddedSuccessfully: "Valeurs ajoutées avec succès",
          Selectsharepourcent: "Sélectionner un pourcentage",
          selectdatefin: "Sélectionner une date de fin",
          PleaseinputtheShareHolders: "Veuillez saisir les actionnaires",
          Descriptionplaceholder: "Veuillez écrire une description",
          Open: "Ouvert",
          Waiting: "En attente",
          Suspended: "Suspendu",
          Lost: "Perdu",
          writeacostumer: "Veuillez écrire le nom du client",
          writeacostumerplaceholder: "Écrire le nom du client",
          writeaproduct: "Veuillez écrire le nom du produit",
          writeaproductplaceholder: "Écrire le nom du produit",
          Descriptionplace: "Écrire une description",
          Pleaseenteradate: "Veuillez saisir une date",
          Selectopportunitystatus: "Sélectionner le statut de l'opportunité",
          orderbookdeleted: "Carnet de commande supprimé",
          assignmentdeleted: "Assignment supprimé",
          yearnotexisting: "Les données de cette année n'existe pas",
          orderbookcreation: "Carnet de commande ajouté",
          orderbookupdate: "Carnet de commande modifié",
          deleterow: "Êtes-vous sûr(e) de vouloir supprimer cette ligne ?",
          orderbooknamerequired: "Veuillez saisir le nom de la commande",
          assignmentnamerequired:
            "Veuillez saisir le nom de suivi d'affectation",
          billedhours: "Total d'heures facturées",
          availablehours: "Total des heures disponibles",
          holidayhours: "Total d'heures de congé",
          vacationhours: "Total d'heures de vacances",
          illnesshours: "Total d'heures de maladie",
          unpaidhours: "Total d'heures non rémunérées",
          paidhours: "Total d'heures rémunérées",
          billingavg: "Moyenne de facturation",
          grossmarginavg: "Moyenne de marge brute",
          estimatedclientinvoice: "Total des factures clients estimées",
          totalclientinvoice: "Total des factures clients",
          variance: "Variance",
          enterworkhours: "Entrer les heures de travail",
          workhoursrequired: "Heures de travail requises",
          workhours: "Heures de travail",
          numberofday: "Nombre de jours",

          costrate: "Taux de coût",
          sellingrate: "Taux de vente",
          numberofdayrequired: "Nombre de jours requis",
          ErrorWhileDeletingassignment:
            "Erreur lors de la suppression de l'affectation",
          assignmentcreation: "Affectation créée avec succès",
          assignmentupdate: "Affectation mise à jour avec succès",
          assignmentcreationerror:
            "Erreur lors de la création de l'affectation",
          NameofTheassignment: "Nom de l'affectation",
          creationdate: "Date de création",
          opportunities: "Opportunités",
          opportunitybook: "Carnet d'opportunités",
          opportunitybookdeleted: "Opportunité supprimée",
          opportunitybookcreation: "Opportunité ajoutée",
          opportunitybookupdate: "Opportunité modifiée",
          opportunitybooknamerequired:
            "Veuillez saisir le nom de l'opportunité",
          Createopportunity: "Créer une opportunité",
          for: "pour ",
          Orderdeletedsuccessfully: "Commande supprimée avec succès",
          Opportunitydeletedsuccessfully: "Opportunité supprimée avec succès",
          OrderupdatedSuccessfully: "Commande mise à jour avec succès",
          OpportunityupdatedSuccessfully: "Opportunité mise à jour avec succès",
          YourCompanyhasbeenaddedsuccessfully:
            "Votre entreprise a été ajoutée avec succès",
          TheCompanyhasbeenupdatedsuccessfully:
            "La société a été mise à jour avec succès",
          Addanothercompany: "Ajouter une autre entreprise",
          OpportunityrevenuedetailsupdatedSuccessfully:
            "Les détails des revenus de l'opportunité ont été mis à jour avec succès",
          ReturntoGeneralInformations: "Retour aux Informations Générales",
          duplicatelastorderbook: "Dupliquer le dernier carnet de commandes",
          duplicatelastassignment: "Dupliquer le dernier assignment",
          duplicatelastopportunitybook:
            "Dupliquer le dernier carnet d'opportunité",
          Gained: "Gagné",
          AddCompany: "Ajouter une entreprise",
          SelectStartPeriod: "Sélectionnez la période de début",
          textButtonAJT:
            "Veuillez remplir les champs obligatoires ci-dessous et cliquer sur le bouton Soumettre pour ajouter les informations de votre entreprise",
          generalInformation: "Informations générales",
          Pleaseinputthecompanyname: "Veuillez saisir le nom de l'entreprise",
          ProvinceSelect: "Sélectionnez la province",
          selectthetypeofindustry: "Sélectionnez le type d'industrie",
          AddnewtypeIndustry: "Ajouter un nouveau type d'industrie",
          selectthebudget: "Sélectionnez un budget",
          PleaseInputTheBusinessNumber:
            "Veuillez entrer le numéro d'entreprise",
          IndustryTypes: "Types d'industrie",
          selectmanagerstitles: "Sélectionnez les titres du manager",
          StartPeriod: "Période de début",
          selectdatedebut: "Sélectionner une date de début",
          pleaseinputthefoundingdate: "Veuillez entrer la date de fondation",
          pleaseinputthecity: "Veuillez sélectionner une ville",
          pleaseinputtheaddress: "Veuillez entrer l'adresse",
          addnewmanager: "Ajouter un nouveau manager",
          selectthemarket: "Sélectionnez un marché",
          MainCustomers: "Clients principaux",
          selectthemaincustomers: "Sélectionnez les clients",
          RevenueModel: "Modèle de revenus",
          selecttherevenuemodel: "Sélectionner les modèles de revenus",
          AddnewmarketButton: "Ajouter un nouveau marché",
          AddnewcustomerButton: "Ajouter un nouveau client",
          Pleaseentercustomername: "Veuillez entrer le nom du client",
          market: "Marché",
          Pleaseinputthe: "Veuillez saisir le",
          RevenueModelButton: "Ajouter un nouveau modèle de revenus",
          BusinesspartnersButton: "Partenaires d'affaires",
          selectthebusinesspartners: "Sélectionner les partenaires d'affaires",
          Addnewbusinesspartner: "Ajouter de nouveaux partenaires d'affaires",
          Descriptionofservicesandproducts:
            "Description des services et produits",
          selectShareHolders: "Sélectionner un actionnaire",
          Descriptionofoffer: "Description de l'offre",
          Strategictargets: "Objectifs stratégiques",
          selectthestrategictarget: "Sélectionner les cibles stratégiques",
          selectthetypeofactivities: "Sélectionner le type d'activités",
          typeofactivities: "Type d'activités",
          pleaseselectthecountry:
            "Veuillez sélectionner le pays de l'entreprise",
          selecttheproductsservices: "Sélectionner des produits/services",
          Typeofactivities: "Type d'activités",
          ProductsServices: "Produits/Services",
          strategicplanning: "Planification stratégique",
          StrategictargetsButton: "Ajouter de nouvelles cibles stratégiques",
          TypeofactivitiesButton: "Ajouter de nouveaux types d'activités",
          ProductsServicesButton: "Ajouter de nouveaux produits/services",
          Startyear: "Année de début",
          YearsInterval: "Intervalle d'années",
          Pleaseinputthetypeofstrategictarget:
            "Veuillez saisir le type d'objectif stratégique",
          Pleaseinputthedetailseofstrategictarget:
            "Veuillez saisir les détails de l'objectif stratégique",
          Managementteam: "Équipe de direction",
          Createanewmanager: "Créer un nouveau gestionnaire",
          Lastname: "Nom du dirigeant",
          Firstname: "Prénom",
          Titles: "Titres",
          Title: "Titre",
          addedsuccessfully: "Les valeurs ont été ajoutées avec succès",
          Yearsofexperience: "Années d'expérience",
          cancel: "Annuler",
          create: "Créer",
          Delete: "Supprimer",
          save: "Enregistrer",
          edit: "Modifier",
          details: "Détails",
          Managetitles: "Gestion des titres",
          Createanewtitle: "Créer un nouveau titre",
          Addtitle: "Ajouter un titre",
          EditTitles: "Modifier les titres",
          Titlelabel: "Titre d'étiquette",
          Leadersname: "Nom du dirigeant",
          selectleader: "Sélectionner un dirigeant",
          Addmanager: "Ajouter le dirigeant",
          Legalstructure: "Structure juridique",
          ShareHolders: "Actionnaires",
          CreateanewShareholder: "Ajouter l'actionnaire",
          Name: "Nom",
          Nameshareholders: "Nom des actionnaires",
          percentshares: "% d'actions",
          Shares: "Actions",
          Startdate: "Date de début",
          Addshareholders: "",
          submit: "Soumettre l'entreprise",
          Selectdate: "Sélectionner une date",
          Selectmonth: "Sélectionner un mois",
          Selectyear: "Sélectionner une année",
          Pleaseselectacustomer: "Veuillez sélectionner un client",
          Pleaseselectaproduct: "Veuillez sélectionner un produit",
          Customer: "Client",
          Product: "Produit",
          Startdatee: "Date de début",
          Enddate: "Date de fin",
          Priceperday: "Prix par jour",
          Description: "Description",
          OrdercostdetailsupdatedSuccessfully:
            "Détails du coût de la commande mis à jour avec succès",
          pleaseinputthestartdate: "Veuillez saisir la date de début",
          pleaseselectthetypeofindustry:
            "Veuillez sélectionner le type d'industrie",
          pleaseselectthemarket: "Veuillez sélectionner le marché",
          pleaseselectthebudget: "Veuillez choisir le budget",
          pleaseselectthecustomer: "Veuillez choisir un client",
          pleaseselecttherevenuemodel:
            "Veuillez sélectionner un modèle de revenu",
          PleaseInputTheStartPeriod: "Veuillez saisir la période de début",
          pleaseselectthebusinesspartner:
            "Veuillez sélectionner le partenaire commercial",
          pleaseselecttheactivitytype:
            "Veuillez sélectionner le type d'activité",
          pleaseselecttheproductservice:
            "Veuillez sélectionner le service/produit",
          Commands: "Commandes",
          RevenueDetails: "Détails des revenus pour",
          January: "Janvier",
          February: "Février",
          March: "Mars",
          April: "Avril",
          May: "Mai",
          June: "Juin",
          July: "Juillet",
          August: "Août",
          September: "Septembre",
          October: "Octobre",
          November: "Novembre",
          December: "Décembre",
          Action: "Action",
          CreateCustomer: "Créer un Client",
          CreateProduct: "Créer un Produit",
          Createbook: "Créer une commande",
          RevenueSummary: "Récapitulatif des revenus",
          Createaneworder: "Créer une nouvelle commande",
          Customersname: "Nom du client",
          Productslabel: "Libellé du produit",
          CreateanewCustomer: "Créer un nouveau client",
          CreateanewProduct: "Créer un nouveau produit",
          Createanewopportunity: "Créer une nouvelle opportunité",
          Selectacompanytodisplayitsdata:
            "Sélectionner une entreprise pour afficher ses données",
          add12months: "fr",
          Pleaseinputthemanagertitle:
            "Veuillez entrer le titre du gestionnaire",
          Pleaseinputthemanagerfirstname:
            "Veuillez saisir le nom du gestionnaire",
          Pleaseinputthemanagerlastname:
            "Veuillez saisir le prénom du gestionnaire",
          titlePage: "Suivi de la planification stratégique",
          title: "Créer un nouvel objectif stratégique",
          statut: "Statut",
          Progression: "Progression",
          Departement: "Département",
          pleaseinputthepostalcode: "Veuillez saisir le code postal",
          Pleaseenterproductlabel: "Veuillez saisir le libellé du produit",
          Finance: "Financement",
          Loansmanagement: "Gestion des Prêts",
          Create: "Créer",
          Creationdate: "Date de création",
          Loanname: "Nom du prêt",
          Details: "Détails",
          Operation: "Opération",
          //Create Pret
          Loan: "Prêt",
          Addaloan: "Ajouter un prêt",
          Creditor: "Créancier",
          Loanamount: "Montant du prêt",
          Terms: "Termes",
          Loandate: "Date de l'emprunt",
          Capitalfreeperiod: "Congé de capital",
          Interestrates: "Taux d'intérêt",
          Particularconditions: "Conditions particulières",
          chooseaterm: "Choisir une durée",
          sixtymonths: "soixante mois",
          thirtymonths: "trente mois",
          ninetymonths: "quatre-vingt-dix mois",
          EditePret: "Modifier le Prêt",
          Edit: "Modifier",
          Cancel: "Annuler",
          History: "Historique",
          Margin: "Marge",
          Addamargin: "Ajouter une marge",
          Marginsmanagement: "Gestion des marges",
          Institutionname: "Nom de l'institution",
          Authorizedamount: "Montant autorisé",
          EditerlaMarge: "Editer la marge",
          Suretodelete: "Voulez-vous vraiment supprimer ?",
          Pleaseselectastatus: "Veuillez sélectionner un statut",
          yes: "Oui",
          no: "Non",
          Label: "Étiquette",
          SearchtoSelect: "Rechercher pour sélectionner",
          newtarget: "Nouvelle cible",
          strategictargets: "Cibles stratégiques",
          detail: "Détail",
          status: "Statut",
          progression: "Avancement",
          departements: "Départements",
          targeteditedsuccessfully: "Cible modifiée avec succès",
          pleaseinputthetype: "Veuillez entrer le type",
          CreateanewStratigicTarget: "Création d'une cible stratégique",
          NotStarted: "Non entamé",
          LowProgress: "Faibles progrès",
          MeduimProgress: "Progrès moyen",
          HighProgress: "Progrès élevé",
          Done: "Terminé",
          associateastrategictarget: "Associer une cible stratégique",
          selectatarget: "Sélectionner une cible",
          updatestep: "Modification de la cible",
          associate: "Associer",
          dissociate: "Dissocier",
          dissociatemsg: "Voulez-vous vraiment supprimer l'objectif",
          newstep: "Nouvelle étape",
          priority: "Priorité",
          projectnumber: "No. de projet",
          responsable: "Responsable",
          predicteddate: "Date prévue",
          enddate: "Date de fin",
          comments: "Commentaires",
          createanewstep: "Création d'une nouvelle étape",
          Selectaresponsibleplease: "Sélectionner un responsable",
          Enterprojectnumberplease: "Entrer le numéro du projet",
          Selectanestimatedfinishdate: "Sélectionner une date estimée de fin",
          Selectadate: "Sélectionner une date",
          Selectapriority: "Sélectionner une priorité",
          Enteracommentplease: "Entrer un commentaire",
          activity: "Activité",
          activities: "Activités",
          createanewactivity: "Création d'une nouvelle activité",
          enteralabl: "Entrer une étiquette",
          selectastatus: "Sélectionner un statut",
          Activityeditedsuccessfully: "Activité modifiée avec succès",
          Activitdeletedsuccessfully: "Activité supprimée avec succès",
          activityname: "Nom de l'activité",
          Goback: "Retourner",
          High: "Haute",
          Medium: "Moyenne",
          Low: "Faible",
          Updateactivity: "Modifier l'activité",
          newactivity: "Nouvelle activité",
          Cannotdissociateatargetifithasactivitiesinitssteps:
            "Impossible de dissocier une cible si elle a des activités dans ses étapes",
          Targetassociated: "Cible stratégique associée au département",
          Targetdissociated: "Cible stratégique dissociée du département",
          CannotdeleteaStepifithasoneormoreactivities:
            "Impossible de supprimer une étape si elle comporte une ou plusieurs activités",
          Chartaccounts: "Plan comptable",
          Print: "Imprimer",
          Createanewaccount: "Créer un nouveau compte",
          Upload: "Télécharger",
          Gifi: "IGRF",
          Class: "Classe",
          Category: "Catégorie",
          GLnumber: "No. GL",
          Chartofaccount: "Plan comptable",
          Account: "Compte",
          Additionalinformation: "Informations supplémentaires",
          Annualbudget: "Budget annuel",
          Budgetbreakdown: "Répartition du budget",
          Note: "Remarque",
          Pleaseinputtheaccount: "Veuillez entrer le compte",
          Pleaseinputtheclass: "Veuillez entrer la classe",
          Pleaseinputthecategory: "Veuillez entrer la catégorie",
          Pleaseinputthetype: "Veuillez entrer l'IGRF",
          Pleaseinputtheannualbudget: "Veuillez entrer le budget annuel",
          GLaccount: "Compte GL",
          Hypotheses: "Hypothèses",
          Createanewhypothesis: "Créer une nouvelle Hypothèse",
          Year: "Année",
          Annualincrease: "Augmentation annuelle",
          Beginon: "Commence le",
          GLAccountsTheirHyphothesis: "Comptes GL et leurs hypothèses",
          Filterbytype: "Filtrer par IGRF",
          Selectafinancialstatementtype:
            "Sélectionner un type d'état financier",
          Relatedgifi: "IGRF associé",
          Pleaseinputtheyear: "Veuillez entrer l'année",
          Pleaseinputtheannualincrease:
            "Veuillez entrer l'augmentation annuelle",
          Pleaseselectamonth: "Veuillez sélectionner un mois",
          Pleaseinputthehypothesis: "Veuillez entrer l'hypothèse",
          UpdatedSuccessfully: "Mise à jour réussie",
          Alltypes: "Tous les IGRF",
          CreateHypothesisss: "Créer une hypothèse",
          Confirmed: "Confirmé",
          ProjectNumber: "Numéro de projet",
          ProjectName: "Nom de projet",
          ProjectFile: "Fiche de projet",
          ProjectFiledetail: "Détail de la fiche de projet",
          InvestmentProjectFilesAddedSuccessfully:
            "Fichiers de projets d'investissement ajoutés avec succès",
          AddfileProject: "Ajouter un fichier de projet",
          AddfileProjecr: "Ajouter un projet de fichier",
          Budgets: "Budget",
          Reals: "Réel",
          Performances: "Performance",
          BudgetConfirmed: "Budget confirmé",
          InvestmentCategories: "Catégorie",
          Investments: "Investissements",
          RealTotal: "Total des réalisations",
          PerformanceTotal: "Total des performances",
          SelectedYear: "Année sélectionnée",
          Categories: "Catégories",
          DeleteInvestment: "Supprimer l'investissement",
          DeleteBudegt: "Supprimer le budget",
          DeleteReal: "Supprimer la réalisation",
          Confirm: "Confirmer",
          Unconfirm: "Annuler la confirmation",
          Investment: "Investissement",
          budgetConfirmed: "Budget confirmé",
          InvestmentSummary: "Résumé des investissements",
          SaveChanges: "Enregistrer",
          BudgetTotal: "Total des budgets",
          BackToInvestments: "Retour aux investissements",
          CreateInvestment: "Créer un investissement",
          CategoriesOfInvestments: "Catégories d'investissements",
          AddNewCategory: "Ajouter une nouvelle catégorie",
          EditCategory: "Modifier la catégorie",
          SaveCategory: "Enregistrer la catégorie",
          DeleteCategory: "Supprimer la catégorie",
          ExportToExcel: "Exporter vers Excel",
          CategoryAddedSuccessfully: "Catégorie ajoutée avec succès",
          CategoryUpdatedSuccessfully: "Catégorie mise à jour avec succès",
          ErrorFetchingCategories:
            "Erreur lors de la récupération des catégories",
          ErrorSavingCategories:
            "Erreur lors de l'enregistrement des catégories",
          categoryDeletedSuccessfully: "Catégorie supprimée avec succès",
          ErrorDeletingCategory:
            "Erreur lors de la suppression de la catégorie",
          Parameters: "Paramètres",
          Pleaseselectyearbeforeaddinginvestment:
            "Veuillez selectioner une année avant d'ajouter un investissement !",
          Selectgroup: "Veuillez selectioner un groupe",
          group: "Groupe",
          PM: "MESURES DE RENDEMENT",
          AME: "EFFICACITÉ DE LA GESTION DES ACTIFS",
          OP: "RENTABILITÉ DE L'EXPLOITATION",
          Pleaseselectcategory: "Sélectionner une catégorie !",
          Financialstatements: "États financiers",
          glnumber: "Numéro de compte GL",
          liabilities: "Passif",
          LiabilitiesDetail: "Détails du passif",
          Liabilitysummary: "Résumé du passif",
          Summary: "Résumé",
          UploadtheReal: "Télécharger les réalisations",
          Maininformation: "Informations principales",
          PleaseSelectclass: "Veuillez sélectionner une classe",
          PleaseSelectcategory: "Veuillez sélectionner une catégorie",
          Hyphothesis: "Hypothèses",
          CreateanewHyphothesis: "Créer une nouvelle hypothèse",
          Average: "Moyenne",
          backtofinancialstatement: "Retour aux états financiers",
          deletStatement: "Détruire",
          Budget: "Budget",
          Difference: "Différence",
          Performance: "Performance",
          Deletethestatment: "Supprimer l'état financier",
          AnnualIncrease: "Augmentation annuelle",
          BeginOn: "Commence le",
          BeginOnMonth: "Commence le",
          CreateHypothesi: "Créer une hypothèse",
          equity: "Avoir",
          EquityDetails: "Détails de l'avoir",
          Equitysummary: "Résumé de l'avoir",
          Asset: "Actif",
          AssetsDetails: "Détails de l'actif",
          AssetSummary: "Résumé de l'actif",
          Taxable: "Imposable",
          PayementTerme: "Terme de paiement",
          ExpenseDetails: "Détails des dépenses",
          Expense: "Dépense",
          Expenses: "Dépenses",
          ExpenseSummary: "Résumé des dépenses",
          Revenue: "Revenu",
          Revenues: "Revenus",
          sellingPrice: "Prix de vente",
          Employee: "Employé",
          SelectEmployee: "Sélectionner un employé",
          ExpectedSoldUnits: "Unités vendues prévues",
          Areyousuretoconfirm: "Êtes-vous sûr de confirmer",
          RealSoldUnit: "Unité vendue réelle",
          HumanResource: "Information sur les employés",
          HumanResources: "Ressources humaines",
          AddEmployee: "Ajouter un employé",
          EmployeesManagement: "Gestion de la main d'œuvre",
          CreateNewEmployeeFor: "Ajouter un nouvel employé pour",
          EmployeeFirstName: "Prénom",
          EmployeeFirstNameErrorMessage: "Entrez le prénom",
          EmployeeLastName: "Nom",
          EmployeeLastNameErrorMessage: "Veuillez saisir le nom",
          EmployeeGender: "Genre",
          ChooseFile: "Choisir un fichier",
          EmployeeGenderErrorMessage: "Sélectionnez le genre",
          EmployeeStatuss: "Statut d'employé",
          EmployeeStatusErrorMessage: "Sélectionnez un statut",
          AgeAverage: "Moyenne d'âge",
          AgeAverageErrorMessage: "Sélectionnez une moyenne",
          VisibilityMinority: "Minorité visible",
          RecognizedExeperienceYears: "Années d'expérience reconnues",
          RecognizedExeperienceYearsErrorMassage: "Entrez un nombre",
          YearsOfExperienceTotal: "Nombre total d'années d'expérience",
          YearsOfExperienceTotalErrorMessage: "Entrez un nombre",
          AnnualSalary: "Salaire annuel",
          AnnualSalaryErrorMessage: "Entrez un nombre",
          SocialAdvantages: "Avantages sociaux",
          SocialAdvantagesErrorMessage: "Sélectionnez un nombre",
          BreakDown: "Répartition",
          Vacation: "Congé",
          Bonus: "Bonus",
          Vaccation: "Vacances",
          BasicConditions: "Conditions de base",
          OtherAdvantages: "Autres avantages",
          JobPostingStartDate: "Date de début de l'offre d'emploi",
          JobPostingStartDateErrorMessage: "Sélectionnez une date",
          InsuranceContribution: "Contribution d'assurance",
          Job: "Emploi",
          ImmediateSuperior: "Supérieur immédiat",
          TitleErrorMessage: "Sélectionnez un titre",
          JobClassification: "Classification de l'emploi",
          JobClassificationErrorMessage: "Sélectionnez la classification",
          HiringDate: "Date d'embauche",
          HiringDateErrorMessage: "Sélectionnez une date",
          Department: "Département",
          AssignmentTracking: "Suivi d'affectation",
          DeleteDepartment: "Supprimer le département",
          AddDepartment: "Ajouter un département",
          SelectDepartment: "Sélectionnez un département",
          Alldepts: "Tous les départements",
          DepartmentErrorMessage: "Le département est obligatoire",
          PayPeriod: "Période de paie",
          JobStatus: "Statut d'emploi",
          PostStatus: "Statut de poste",
          DepartureDateErrorMessage: "Sélectionnez une date",
          HumanResourcesSummary: "Résumé ressources humaine",
          HumanResourcesPlanning: "Dépenses en salaires",
          AverageAge: "Âge moyen",
          currentform: "Formulaire courant",
          previousupdate: "Mise à jour précédente",
          HumanResourcesPlanningHeader: "Planification RH",
          SelectDepartmentPlaceHolder: "Sélectionnez un département",
          SelectYearPlaceHolder: "Sélectionnez une année",
          CreateNewHREntry: "Créer",
          Real: "Réel",
          Annual: "Annuel",
          Salary: "Salaire",
          JobTitle: "Titre d'emploi",
          GeneralIdentification: "Identification générale",
          Active: "Actif",
          Male: "Homme",
          Female: "Femme",
          UnpaidLeave: "Congé sans solde",
          Illness: "Maladie",
          InvoluntaryDeparture: "Départ involontaire",
          VoluntaruDeparture: "Départ volontaire",
          Invalidity: "Invalidité",
          Under20: "Moins de 20",
          Between20And25: "Entre 20 et 25",
          Between26And30: "Entre 26 et 30",
          Between31And39: "Entre 31 et 39",
          Between40And50: "Entre 40 et 50",
          Beyond50: "Plus de 50",
          SelectAJobClassification: "Sélectionnez une classification d'emploi",
          Weekly: "Hebdomadaire",
          In15Days: "Dans 15 jours",
          Monthly: "Mensuel",
          FullTime: "Temps plein",
          PartTime: "Temps partiel",
          Temporary: "Temporaire",
          Completed: "Terminé",
          Eliminated: "Éliminé",
          CreateNewDepartment: "Créer un nouveau département",
          UpdateDepartment: "Mettre à jour le département",
          postclassficiation: "Classifications du poste",
          CreateNewPostClassification:
            "Créer une nouvelle classification du poste",
          ImportCharaccounts: "Importez les plans comptables",
          UpdatePostClassification: "Mettre à jour la classification du poste",
          PostClassificationaddedsuccessfully:
            "Classification du poste ajoutée avec succès",
          AddPostClassification: "Ajouter la classe du poste",
          DeletePostClassification: "Supprimer la classe du poste",
          EnterpriseTitles: "Titres de l'entreprise",
          DeleteTitle: "Supprimer le titre",
          CreateNewTitle: "Créer un nouveau titre",
          employeedetails: "Détails de l'employé",
          Estimate: "Estimé",
          ChiffreDAffaire: "Chiffre d'affaires par année",
          ChiffreDAffaireparmois: "Chiffre d'affaires par mois - Année",
          Revenueparproduit: "Revenue par produit -",
          Vente: "Ventes par produit -",
          ChiffreDAffaireBudget: "Chiffre d'affaires / budget",
          RealEstimate: "Réel/estimé",
          thousands: "Milliers",
          budgetatteint: "% Budget atteint",
          Pourcentage: "% Pourcentage",
          depensedessalaires:
            "Dépenses de salaires et sous-traitants par année",
          croissancedeseffectifs: "Croissance des effectifs par année",
          pourcentagedesdepenses:
            "% des dépenses de salaires et sous-traitants / Budget",
          depensedessalairesparstatus:
            "Dépenses de salaires et sous-traitants - Année",
          depensedessalairesparmois:
            "Dépenses de salaires et sous-traitants par mois - Année",
          salary: "Salaires",
          soustraitant: "Sous-traitants",
          totaldepense: "Dépenses totales",
          persons: "Personnes",
          salesdashboard: "Tableau de bord",
          HumanResourcesdashboard: "Tableau de bord",
          grossMargins: "Marges brutes",
          Editmargin: "Modifier le devis",
          NoDevis: "Numéro de devis",
          namedevis: "Nom du devis",
          Orderdetail: "Détail de la commande",
          CustomerName: "Nom du client",
          TotalEstimated: "Total estimé",
          TotalInvoiced: "Total facturé",
          TotalActual: "Total réel",
          totalhourlycost: "Coût horaire total",
          GrossMarginsReport: "Rapport des marges brutes",
          CreateNewGross: "Créer une nouvelle marge brute",
          PrintAll: "Imprimer tout",
          selectedNoDevis: "Numéro de devis sélectionné",
          estimatedInvoice: "Estimé / Facturé",
          estimatedActual: "Estimé / Réel",
          HourlyRate: "Taux horaire",
          totalHours: "Total des heures",
          materialCose: "Coût du matériel",
          otherCost: "Autre coût",
          Marges: "Marges",
          estimated: "Estimé",
          invoiced: "Facturé",
          actual: "Réel",
          noInvoive: "Numéro de facture",
          General: "Général",
          return: "Retour",
          Operationalprioritization: "Priorisation opérationnelle",
          deletethetask: "Voulez-vous vraiment supprimer cette marge brute ?",
          Pleaseinputtheorderdetails:
            "Veuillez entrer le détail de la commande",
          Pleaseinputthecustomer: "Veuillez entrer le nom du client",
          PleaseinputtheDevisName: "Veuillez saisir le numéro de devis",
          Pleaseinputthehourlyrate: "Veuillez entrer le taux horaire",
          Pleaseinputthetotalhours: "Veuillez entrer le nombre total d'heures",
          Pleaseinputtheinvoiceno: "Veuillez entrer le numéro de facture",
          Pleaseinputthematerialcost: "Veuillez entrer les frais de matériel",
          Pleaseinputtheothercosts: "Veuillez entrer les autres frais",
          createnewmarginforenterprise: "Créer une nouvelle marge brute ",
          Pleaseinputthecommissionrate: "Veuillez entrer le taux de commission",
          AddActivityType: "Ajouter un type d'activité",
          Activitytypes: "Type d'activité",
          Activytytypedeletedsuccessfully:
            "Type d'activité supprimé avec succès",
          ActivityTypesupdatedSuccessfully:
            "Type d'activité mis à jour avec succès",

          AddaTypeIndustries: "Ajouter un type industries",
          IndustryTypesupdatedSuccessfully:
            "Type d'industrie mis à jour avec succès",
          IndustryTypesdeletedsuccessfully:
            "Type d'industrie supprimé avec succès",
          AddMarkets: "Ajouter un Marché ",
          MarketsupdatedSuccessfully: "Marché mis à jour avec succès  ",
          Marketsdeletedsuccessfully: "Marché supprimé avec succès",
          AddBusinesspartners: " Ajouter des partenaires commerciaux",
          Pleaseenterlabel: "Veuillez saisir le libellé",
          RevenueModelButtonn: "Ajouter un revenu model",
          AddProducttt: "Ajouter un produit ",
          Productsdeletedsuccessfully: "Produit supprimé avec succès",
          ProductsupdatedSuccessfullyy: "Produit mis à jour avec succès",
          AddCustomers: "Ajouter un client",
          AddDepartement: "Ajouter un departement",
          CustomersupdatedSuccessfully: "Client mis à jour avec succès",
          Customerdeletedsuccessfully: "Client supprimé avec succès",
          EnterpriseDepartmentwasDeletedsuccessfully:
            "Le département d'entreprise a été supprimé avec succès",
          EnterpriseDepartmenteditedsuccessfully:
            "Le département d'entreprise a été mis à jour avec succès",
          EnterpriseDepartmentAddedsuccessfully:
            "Le département d'entreprise a été ajouter avec succès",
          BusinessPartnersdeletedsuccessfully:
            "Partenaires d'affaires a été supprimé avec succès",
          BusinessPartnersupdatedSuccessfully:
            "Partenaires d'affaires a été mis à jour avec succès",
          AddaBusinesspartners: "Ajouter un Partenaires d'affaires",
          RevenueModelItemupdatedSuccessfully:
            "Modèle de revenus a été mis à jour avec succès",
          RevenueModelItemsdeletedsuccessfully:
            "Modèle de revenus a été supprimé avec succès",
          DepartmentsupdatedSuccessfully:
            "Departement a été mis à jour avec succès ",
          departmentdeletedsuccessfully:
            "Departement a été supprimé avec succès",
          EnterpriseDepartments: "Départments d'enterprise",
          SelectAChartAccount: " Veuillez sélectionner le plan comptable",
          Add: "Ajouter",
          ParametersforActivytyTypee: "Paramètres du types d'activités",
          ParametersforBusnissepartners: "Paramètres du Partenaires d'affaires",
          Parametremarkets: "Paramètres du Marchés",
          ParametersforCustomer: "Paramètres du  clients",
          Parametersforindustrytypes: "Paramètres du Type d'industrie",
          ParametersforMargeCapital: "Paramètres du capital de marge",
          Parametersformarketingtypes: "Paramètres du types de marketing",
          PaymentTerms: "Conditions de paiement",
          Parametersforpaymentterms: "Paramètres du conditions de paiement",
          ParametersforProduct: "Paramètres des produits",
          Parametersforrevenuemodel: "Paramètres du modèle de revenus",
          Parametersforterms: "Paramètres des termes ",
          Investismentcategory: "Catégorie d'investissement",
          investismentcategoryupdatedSuccessfully:
            "Catégorie d'investissement a été mis à jour avec succès",
          investismentcategorydeletedsuccessfully:
            "Catégorie d'investissement  a été supprimé avec succès",
          Parametersforinvestissementcategory:
            "Paramètres du catégorie d'investissement",

          Parametersforprojectstatus: "Statuts des projets",
          projectstatuswassuccessfullydeleted:
            "Statut de projet a été supprimé avec succès ",
          PleasefillinatleastonelabelFrenchorEnglish:
            "Veuillez remplir au moins une étiquette en français ou en anglais",
          ProjectOperationalPrioritiesswassuccessfullydeleted:
            "La priorité opérationnelle du projet a été supprimée avec succès",
          ProjectOperationalPrioritiesshasbeensuccessfullyupdated:
            "La priorité opérationnelle du projet a été mises à jour avec succès",
          Revenutypehasbeensuccessfullyupdated:
            "Le type de revenue a été mises à jour avec succès",
          Revenutypwassuccessfullydeleted:
            "Le type de revenue a été supprimer avec succés",
          projectstatushasbeensuccessfullyupdated:
            "le statut du projet a été mis à jour avec succès",
          Youcannotdeletebecauseitisassociated:
            "Vous ne pouvez pas supprimer car il est associé",

          Projectcategorizations: "Catégorisations du projet",
          Projectacquisitiontypes: "Types d'acquisition des projets",
          Projectcomplexitytypes: "Types de complexité des projets",
          Projectoperationalpriorities: "Priorisation opérationnelle",
          Projectacquisitions: "Acquisitions",
          ProjectdescriptionandContext: "Description et contexte du projet",
          benefitsDescription: "Description",
          Scope: "Envergure",

          projectComplexity: " Niveau de complexité",
          Projectacquisitionstype: "Type d'acquisition",
          Tableofactivities: "Tableau des activités",
          Addactivity: "Ajouter une activité",
          Addexpense: "Ajouter une dépense",
          tableofInvestment: "Tableau d'investissement",
          Expensetype: "Type de dépenses",
          ExpenseTypesswassuccessfullydeleted:
            "Le type de dépense aété supprimé avec succés",
          ExpenseTypeshasbeensuccessfullyupdated:
            "Le type de dépense a été mis à jour avec succès.",
          Recurrent: "Récurrente",

          PlannedInvestment: "Investissement prévu",
          Pleaseinputtheprojectnumber: "Veuillez saisir le numéro du projet",
          NewRecurrentExpenseEntry: "Dépenses récurrentes",

          Projecteffortscales: "Envergure des efforts",
          PrincipaleProjectobjectivess: "Objectif du projet principal",

          projectdescription: "Description du projet",
          Projectglobalscales: "Envergure globale",
          Projectbenefits: "Bénéfices",
          Projectimpacts: "Impacts ",

          SettingsPagee: "Page paramètres",
          Marketingtypes: "Type de marketing",
          Projectobjectives: "Objectifs du projet",
          Projecteffortgolbalwassuccessfullydeleted:
            "Échelle globale de projet a été supprimé avec succès",

          Projecteffortgolbalhasbeensuccessfullyupdatedd:
            "Échelle globale de projet a été modifié avec succès",
          ProjectObjectiveswassuccessfullydeleted:
            "L'objectif du projet a été supprimé avec succès",
          ProjectImpactswassuccessfullydeleted:
            " Projet impact a été supprimé avec succès",

          ProjectImpacthasbeensuccessfullyupdatedd:
            "Projet impact a été modifier avec succés",
          ProjectEffortScaleswassuccessfullydeleted:
            "Échelle d'effort de projets a été supprimé avec succès",
          Projectbenefitshasbeensuccessfullyupdatedd:
            "Le bénéfice de projet  a été mises à jour avec succès",
          Projectacquisitionstypeswassuccessfullydeleted:
            "Type d'acquisition du projet a été supprimé avec succès",
          Projectacquisitionswassuccessfullydeleted:
            "L'acquisition du projet a été supprimé avec succès",
          Configurationentity: "Entité de configuration",
          Revenutype: "Type de revenu",
          RecurrentFrequency: "Fréquence de récurrence",
          SelectRevenueType: "Sélectionnez le type de revenu",
          Notrecurrent: "Pas récurrent",
          Projectacquisitionshasbeensuccessfullyupdatedd:
            "L'acquisition du projet a été mises à jour avec succès",
          Projectacquisitionstypeshasbeensuccessfullyupdated:
            "Type d'acquisition du projet a été mises à jour avec succès",
          ProjectComplexityTypeswassuccessfullydeleted:
            "Type de compléxité de projet  a été supprimé avec succès",

          Projectcategorizationshasbeensuccessfullyupdated:
            "La catégorisation de projets a été mises à jour avec succès",
          Projectbenefitwassuccessfullydeleted:
            "Le bénéfice de projet a été supprimé avec succès",

          projectcategorizationswassuccessfullydeleted:
            "La catégorisation de projet a été supprimée avec succès",
          ProjectComplexityTypeshasbeensuccessfullyupdated:
            "Type de compléxité de projet  a été modifié avec succès",
          ProjectEffortScaleshasbeensuccessfullyupdatedd:
            "Échelles d'effort des projets a été modifié avec succès",

          Projectobjectivesbeensuccessfullyupdatedd:
            "L' objectifs du projet a été mis à jour avec succès",
          MarketingTypeupdatedSuccessfully:
            "Type de marketing a été mis à jour avec succès",
          Marketingtypedeletedsuccessfully:
            "Type de marketing a été supprimé avec succès",
          Addmarge: "Ajouter une marge",
          AddTerms: "Ajouter un term",
          Margedeletedsuccessfully: "Marge a été supprimé avec succès",
          margeupdatedSuccessfully: "Marge a été mis à jour avec succès ",
          Termdeletedsuccessfully: "Terme supprimé avec succès",
          TermsupdatedSuccessfully: "Term a été mis à jour avec succès",
          ParametersforActivytyType: "Paramètres du type d'activité",
          ParametersforBusnissepartnerss:
            "Paramètres du Partenaires d'affaires",
          ParametersIndustrytype: "Paramètres du type d'industrie",
          add: "Ajouter",
          departments: "Départements",
          entreprisedepartments: "Départements d'entreprise",
          marketing: "Commercialisation",
          CreateMarketingg: "Créer une commercialisation",
          MarketingSummary: " Sommaire de la commercialisation ",
          BackToMarketing: "Retour à la commercialisation",
          budgetTotal: "Budget total",
          Marketingexpenses: "Dépenses marketing",
          pleaseinputthetypeee: "Veuillez entrer le type ",
          pleaseinputthetotal: "Veuillez entrer le total",
          pleaseinputthedes: "Veuillez entrer une description",
          Descriptionofprojet: "Description du projet",
          Objectifprincipalduprojet: "Objectif principal du projet",
          Marketingbudgetdeletedsuccessfully:
            "Budget marketing supprimé avec succès",
          Reportingsection: "Générateur de rapports",
          Reports: "Rapports",
          IncomeStatement: "Relevé de revenus",
          pret: "Prêt",
          VeuillersaisirleMontant: "Veuiller saisir le montant",
          Veuillezsaisiruntauxdintéret: "Veuiller saisir un taux d'intéret",
          VeuillersaisirletitredeCreancier:
            "Veuiller saisir le titre de créancier",
          VeuillersaisirletitredePrêt: "Veuiller saisir le titre de Prêt",
          Choisirsonterme: "Choisir sans terme",
          Choisircongédecapital: "Choisir congé de capital",
          absencedecongé: "Absence de congé",
          CapitalfreeperiodSelect: "Veuillez sélectionner un congé de capital",
          sixmonths: "6 mois",
          twelvemonths: "12 mois",
          EditePrett: "Modifier le prêt",
          marge: "Marge",
          pleaseentertheAuthorizedamount: "Veuillez entrer le montant",
          Dashboardsettings: "Paramètre du tableau de bord",
          TotalDepartement: "Total des départements",
          TotalEmployee: "Total des employés",
          TotalClient: "Total des clients",
          TotalProduct: "Total des produits",
          Enterprise: "Entreprise",
          ModelsName: "Nom du modèle",
          Complexity: "Complexité",
          Nameofrequester: "Nom du demandeur",
          Pleaseinputthenameofrequester: "Veuillez entre le nom du demandeur",
          CashflowModels: "Modèles de cashflow",
          NewModel: "Nouveau modèle",
          Selectanenterprise: "Sélectionner une entreprise",
          Customization: "Personnalisation",
          Fromamodel: "À partir d'un modèle",
          Selectanexistingmodel: "Sélectionner un modèle existant",
          choosecomplexity: "choisir la complexité ",
          PleaseInputYourFirstName: "Veuillez saisir le prénom",
          Passwordsdonotmatch: "Les mots de passe ne correspondent pas",
          InitialPart: "Partie initiale",
          InflowPart: "Partie entrante",
          selectGLNumber: "Sélectionnez le numéro GL",
          accountname: "Nom du compte",
          InflowTotal: "Entrée Total",
          InterimFinancing: "Financement intérimaire",
          InflowGlobalTotal: "Total globale des entrées",
          Section: "Séction",
          OutflowTotal: "Outflow total",
          Differencebetweeninflowandoutflow:
            "Différence entre les entrées et les sorties",
          FinalCash: "Cash final",
          SummaryPart: "Partie récapitulative",
          DuplicatechartAccounterror: "Dupliquer plan comptable",
          Modelcreatedwithsuccess: "Modèle créé avec succèss",
          ModelCreation: "Création de modéle",
          Allfieldsmustbefilled: "Tous les champs doivent être remplis",
          Backtomoddelsslist: "Retour à la liste des modèles",
          Previous: "Précédent",
          Next: "Suivant",
          Editingmodel: "Modification du modèle",
          EnterpriseNumber: "Numéro d'entreprise",
          Selectmodelscomplexity: "Sélectionner la complexité du modèley",
          Simple: "Simple",
          Intermediate: "Intermédiaire",
          Advanced: "Avancé ",
          twoweeks: "Deux semaines",
          Enternewmodelname: "Entrez le nouveau nom du modèle",
          Linkingdepartmeent: "lier les départements",
          Areyousuretodeletethiscategory:
            "Etes-vous sûr de supprimer cette catégorie",
          SubCategory: "Sous-catégorie",
          Linkinggroup: "Ratios financiers - paramètres",

          LinkinggroupwithChartofAccounts:
            "lier le groupe avec le plan comptable",
          SubSection: "Sous-séction",
          CashflowSummary: "Résumé de Budget de caisse",
          InitialCash: "Cash initial",
          Variation: "Variation",
          IntermediateCash: "Intermédiaire cash",
          AddMinusSection: "Ajouter une section moins",
          Attempttoduplicateasection:
            "Erreur de tentative de duplication de section",
          Lineofcreditatthestart: "Marge de crédit au début",
          Reimbursementoftheperiod: "Remboursement de la période",
          Lineofcreditattheend: "Marge de crédit à lafin",
          InterimFinancingatthestart: "Financement intérimaire au début",
          InterimFinancingattheend: "IFinancement intérimaire au fin",
          Categoryalreadyexistsorinvalidinput:
            "Error : Category already exists or invalid input",
          Duplicate: "Dupliquer",
          DuplicateLatestCashflow: "Dupliquer le dernier flux de trésorerie",
          Period: "Période",
          Suretocancel: "Êtes-vous sûr de vouloir annuler ?",
          simpleModel: "Modèle simple",
          show: "Afficher",
          modifyInstanceName: "Modifier le nom de l'instance",
          PleaseInputName: "Veuillez entrer un nom",
          ErrorWhileConfirming: "Erreur lors de la confirmation",
          addRow: "Ajouter une ligne",
          deleteRowError: "Erreur lors de la suppression de la ligne",
          pleaseinputname: "Veuillez entrer un nom",
          pleaseinputchartofaccount: "Veuillez entrer un plan comptable",
          pleaseinputhypotheses: "Veuillez entrer une hypothèse",
          pleaseinput: "Veuillez entrer",
          SaveValues: "Enregistrer les valeurs",
          ErrorFetchingData: "Erreur lors de la récupération des données",
          ErrorCreatingCashflow:
            "Erreur lors de la création du flux de trésorerie",
          ErrorDeletingCashflow:
            "Erreur lors de la suppression du flux de trésorerie",
          CashFlowInstances: "Instances",
          cashFlowInstances: "Instances de flux de trésorerie",
          NameUpdatedSuccessfully: "Nom mis à jour avec succès",
          FailedToUpdateName: "Échec de la mise à jour du nom",
          TotaldesactivitésMarketing:
            "Total des activités marketing par année ",
          TotaldesactivitésMarketingparmois:
            "Total des activités marketing par mois - ",
          ActivitésMarketingpartype: "Activités marketing par type ",
          Totaldeschargesparannée: "Total des charges par année ",
          Totaldeschargesannéecourante: "Total des charges année courante ",
          ChargesparGLNumber: "Charges par numéro de compte GL - ",
          Chargestotalesparmois: "Charges totales par mois - ",
          ouvert: "Ouvert",
          attente: "Attente",
          pendu: "Pendu",
          ProjectStatus: "Statut du projet",
          Totaldesventesbystatus: "Répartition des opportunités par statut - ",
          Totaldesventes: "Total des ventes dans le carnet de commande - ",
          Totaldesventes1: "Total des ventes dans le carnet de commande - ",
          Totaldesventes2:
            "Total des Opportunités dans le carnet d’opportunité - ",
          Totaldesventes3:
            "Total des Opportunités dans le carnet d’opportunité - ",
          Totaldesventestotal:
            "Total des ventes pour mois (carnet de commande + carnet d’opportunité) - ",
          ExpenseDashboard: "Tableau de bord dépenses",
          MarketingDashboard: "Tableau de bord marketing",
          InvestmentsDashboard: "Tableau de bord investissement",
          Revenuedashboard: "Tableau de bord revenues",
          Pleaseinputthetype: "Veuillez entrer le type",
          //CashFlow
          Enterprise: "Entreprise",
          ModelsName: "Nom du Modèle",
          Areyousuretodeletethissection:
            "Êtes-vous prêt à supprimer cette section",
          createdAt: "Créer le",
          NewModel: "Nouveau modèle",
          Selectanenterprise: "Sélectionner une entreprise",
          Step: "Étape 2",
          Step1: "Étape 1",
          Modelcreatedsuccessfully: "Modèle créé avec succès",

          Fromamodel: "À partir d'un modèle",
          Selectanexistingmodel: "Sélectionner un modèle existant",
          choosecomplexity: "choisir la complexité ",
          // CReate integrator
          PleaseInputYourFirstName: "Veuillez saisir votre prénom",
          Passwordsdonotmatch: "Les mots de passe ne correspondent pas",
          //Step3
          InitialPart: "Partie initiale",
          Initialization: "Initialisation",
          Next: "Suivant",
          InflowPart: "Partie entrante",
          selectGLNumber: "Sélectionnez le numéro GL",
          accountname: "Nom du compte",
          InflowTotal: "Entrée Total",
          InterimFinancing: "Financement intérimaire",
          InflowGlobalTotal: "Total globale des entrées",
          Section: "Séction",
          OutflowTotal: "Outflow total",
          Differencebetweeninflowandoutflow:
            "Différence entre les entrées et les sorties",
          FinalCash: "Cash final",
          SummaryPart: "Partie récapitulative",
          DuplicatechartAccounterror: "Dupliquer plan comptable",
          Modelcreatedwithsuccess: "Modèle créé avec succèss",
          ModelCreation: "Création de modéle",
          Allfieldsmustbefilled: "Tous les champs doivent être remplis",
          Backtomoddelsslist: "Retour à la liste des modèles",
          Previous: "Précédent",
          Next: "Suivant",
          Editingmodel: "Modification du modèle",
          EnterpriseNumber: "Numéro d'entreprise",
          pleaseselectEnterpriseNumber: "Sélectionner Numéro d'entreprise",
          Selectmodelscomplexity: "Sélectionner la complexité du modèley",
          Simple: "Simple",
          Intermediate: "Intermédiaire ",
          Advanced: "Avancé ",
          twoweeks: "deux semaines",
          Enternewmodelname: "Entrez le nouveau nom du modèle",
          Areyousuretodeletethiscategory:
            "Etes-vous sûr de supprimer cette catégorie",
          SubCategory: "nouvelle sous-catégorie",
          SubSection: "Sous-séction",
          CashflowSummary: "Résumé de Budget de caisse",
          InitialCash: "Cash initial",
          Variation: "Variation",
          IntermediateCash: "Intermédiaire cash",
          AddMinusSection: "Ajouter une section moins",
          Attempttoduplicateasection:
            "Erreur de tentative de duplication de section",
          Lineofcreditatthestart: "Marge de crédit au début",
          Reimbursementoftheperiod: "Remboursement de la période",
          Lineofcreditattheend: "Marge de crédit à lafin",
          InterimFinancingatthestart: "Financement intérimaire au début",
          InterimFinancingattheend: "Financement intérimaire au fin",
          Categoryalreadyexistsorinvalidinput:
            "Error : Category already exists or invalid input",
          Modeldeletedsuccessfully: "Modèle supprimé avec succès",
          Duplicate: "Dupliquer",
          DuplicateLatestCashflow: "Dupliquer le dernier flux de trésorerie",
          CreateNewCashflow: "Créer un nouveau flux de trésorerie",
          Period: "Période",
          Suretocancel: "Êtes-vous sûr de vouloir annuler ?",
          simpleModel: "Modèle simple",
          show: "Afficher",
          modifyInstanceName: "Modifier le nom de l'instance",
          PleaseInputName: "Veuillez entrer un nom",
          ErrorWhileConfirming: "Erreur lors de la confirmation",
          addRow: "Ajouter une ligne",
          deleteRowError: "Erreur lors de la suppression de la ligne",
          pleaseinputname: "Veuillez entrer un nom",
          pleaseinputchartofaccount: "Veuillez entrer un plan comptable",
          pleaseinputhypotheses: "Veuillez entrer une hypothèse",
          pleaseinput: "Veuillez entrer",
          SaveValues: "Enregistrer les valeurs",
          ErrorFetchingData: "Erreur lors de la récupération des données",
          ErrorCreatingCashflow:
            "Erreur lors de la création du flux de trésorerie",
          ErrorDeletingCashflow:
            "Erreur lors de la suppression du flux de trésorerie",
          NameUpdatedSuccessfully: "Nom mis à jour avec succès",
          FailedToUpdateName: "Échec de la mise à jour du nom",
          Week: "Semaine",
          TwoWeeks: "Deux Semaines",
          Month: "Mois",
          AddCategory: "Ajouter Catégorie",
          AddSection: "Ajouter Section",
          salesBudgetByMonth: "Budget de vente par mois",
        },
      },
    },
    lng: Lang,
    fallbacklng: "en",
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });

  useEffect(() => {
    if (pathname === "rtl") {
      setPlacement("left");
    } else {
      setPlacement("right");
    }
  }, [pathname]);

  return (
    <Layout
      className={`layout-dashboard ${
        pathname === "profile" ? "layout-profile" : ""
      } ${pathname === "rtl" ? "layout-dashboard-rtl" : ""}`}
    >
      <Drawer
        title={false}
        placement={placement === "right" ? "left" : "right"}
        closable={false}
        onClose={() => setVisible(false)}
        visible={visible}
        key={placement === "right" ? "left" : "right"}
        width={250}
        className={`drawer-sidebar ${
          pathname === "rtl" ? "drawer-sidebar-rtl" : ""
        } `}
      >
        <Layout
          className={`layout-dashboard ${
            pathname === "rtl" ? "layout-dashboard-rtl" : ""
          }`}
        >
          <Sider
            trigger={null}
            width={250}
            theme="light"
            collapsible
            collapsed={collapsed} // Use collapsed state here
            onCollapse={setCollapsed}
            className={`sider-primary ant-layout-sider-primary ${
              sidenavType === "#fff" ? "active-route" : ""
            }`}
            style={{ background: sidenavType }}
          >
            <Sidenav onCollapse={handleSidenavCollapse} />
            {/* Call toggleCollapse */}
          </Sider>
        </Layout>
      </Drawer>
      <Sider
        // breakpoint="lg"
        // collapsedWidth={visible ? "0" : "80"}
        onCollapse={(collapsed, type) => {
          setCollapsed(collapsed); // Handle collapse
        }}
        trigger={null}
        width={250}
        theme="light"
        className={`sider-primary ant-layout-sider-primary ${
          sidenavType === "#fff" ? "active-route" : ""
        }`}
        style={{
          background: sidenavType,
        }}
      >
        <Sidenav onCollapse={handleSidenavCollapse} />
      </Sider>

      {/* Dynamic margin based on collapse state */}
      <Layout
        style={{
          marginLeft: collapsed ? "80px" : "250px", // adjust the left margin based on collapsed state
          marginRight: "10px", // dynamically apply the right margin
          transition: "margin 0.2s", // smooth transition
        }}
      >
        {fixed ? (
          <Affix>
            <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
              <Header
                onPress={handleSidenavCollapse}
                name={pathname}
                subName={pathname}
                handleSidenavColor={handleSidenavColor}
                handleSidenavType={handleSidenavType}
                handleFixedNavbar={handleFixedNavbar}
              />
            </AntHeader>
          </Affix>
        ) : (
          <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
            <Header
              onPress={handleSidenavCollapse}
              name={pathname}
              subName={pathname}
              handleSidenavColor={handleSidenavColor}
              handleSidenavType={handleSidenavType}
              handleFixedNavbar={handleFixedNavbar}
            />
          </AntHeader>
        )}

        <Content className="site-layout">{children}</Content>

        <Footer />
      </Layout>
    </Layout>
  );
}

export default Main;
